import React from 'react';

const PageNotFound: React.FC = () => {

    return (
        <div className="page-not-found text-center">
            <h1 className="page-not-found__title">403</h1>
            <h2 className="page-not-found__subtitle">Page forbidden</h2>
            <h3 className="page-not-found__descr u-font-size-14">Sorry, but you don't currently have permission to access this page.</h3>
        </div>
    );
}

export default PageNotFound;
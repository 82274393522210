import React, { useContext } from 'react';
import { messageSubmit } from '../../config/functions';
import Chat from '../../Containers/Chat/Chat';
import { WebSocketTypeRes } from '../../Models/ResponseModels/WebSocketModels';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import LivePageSectionHeader from './LivePageSectionTitle';


interface IProps {
    isLivestreamVisible?: boolean,
    showDetails?: boolean,
    showMessages?: boolean,
    showTextArea?: boolean,
}

const LivePageQuestionChat = ({ isLivestreamVisible = false, showDetails = false, showMessages = true, showTextArea = true }: IProps) => {

    const chatRoomID =  window.chatRoomLive;
    const { language } = useContext(ContextAppSettings);

    const showChat = () => {
        const isDisabledChat = !window.userInRole("ChatPartecipant") && !window.userInRole("ChatAdmin") && !window.userInRole("ChatEditor");
        if(isDisabledChat){
            console.log("LivePageQuestionChat - childComponentsLoaded");
            window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
            return null;
        } else {
            //@ts-ignore
            return <Chat match={{ isExact: true, path: '', url:'', params: { key: chatRoomID }}} showDetails={showDetails} showMessages={showMessages} showTextArea={showTextArea} sendAttachments={false} liveWebinar={true} onMessageSubmit={(message: WebSocketTypeRes.IWsChatMessage) => messageSubmit(message, chatRoomID)} />
        }
    }

    return (
        <section className="live-page-question-chat-wrapper">
            <div className="live-page-question-chat">
                <LivePageSectionHeader 
                    title={ translations.chat.header_title[language] }
                    imgSource="assets/icons/live_questions_icon.png"
                    subtitle={ translations.chat.header_subtitle[language] }
                />
                { showChat() }
            </div>
        </section>
    )

}

export default LivePageQuestionChat;




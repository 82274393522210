import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes, faInfo, faCheck } from '@fortawesome/free-solid-svg-icons';


interface Button {
    text: string,
    action: () => any
}

interface IProps {
    title: string,
    type: "error" | "warning" | "info" | "success",
    text: string,
    buttons: Button[],
    isVisible?: boolean,
    fnHideModal?: () => void
}

const ModalMessage = ({ title, type, text, buttons, isVisible = true, fnHideModal }: IProps) => {

    const renderIcon = () => {
        let icon: any;
        switch(type){
            case "error":
                icon = faTimes;
                break;
            case "warning":
                icon = faExclamationTriangle;
                break;
            case "info":
                icon = faInfo;
                break;
            case "success":
                icon = faCheck;
                break;
        }
        return <FontAwesomeIcon icon={icon} className="mr-2" />
    }

    return (
        <Modal className={`modal-message ${type}`} show={isVisible} onHide={fnHideModal} centered>
            <Modal.Header>
                <Modal.Title>
                    {renderIcon()}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { text }
            </Modal.Body>
            {
                buttons.length > 0 && 
                <Modal.Footer>
                    {
                        buttons.map(button => {
                            return <Button withClass={["small"]} clicked={button.action}>{button.text}</Button>
                        })
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}

export default ModalMessage;
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from '../../config/axios';
import { reduceFileSize } from '../../config/functions';

import Button from '../_UI/Button/Button';
import ModalMessage from '../_UI/Modal/ModalMessage';
import Gallery from '../Gallery/Gallery';
import TakePictureModal from '../TakePicture/TakePictureModal';


interface IProps {
    isVisible?: boolean,
    fnHideModal?: () => void
}

const GalleryModal = ({ isVisible = true, fnHideModal }: IProps) => {

    const [isTakePictureVisible, showTakePicture] = useState<boolean>(false);
    const [uploadingStatus, setUploadingStatus] = useState<string>('end');
    const [fileToAdd, seFileToAdd] = useState<{ file: File, resourceID: number } | null>(null);

    // const resourceCategoryID = process.env.NODE_ENV === 'development' ? 18 : 22;
    const resourceCategoryID = 22;

    // -- Open take a picture to get a photo (from webcam or file)
    const addPhoto = () => {
        showTakePicture(true);
    }

    // -- Close take a picture and upload the photo
    const uploadPhoto = (image: File | null) => {
        showTakePicture(false);
        setUploadingStatus('progress');
        if(image === null) return;
        // -- MAX SIZE: 500KB
        reduceFileSize(image, 500*1024, 1600, 1600, 0.9, async (file: File) => {
            if(file){
                let resourceData = new FormData();
                resourceData.append('AttachedFile', '');
                resourceData.append('ResourceTypeID', 'IMAGE');
                resourceData.append('ResourceCategoryID', resourceCategoryID.toString());
                resourceData.append('Public', 'true');
                resourceData.append('Published', 'true');
                resourceData.append('IsExternal', 'false');
                resourceData.append('Priority', '0');
                resourceData.append('AttachedFile', file);
                // -- save picture uploaded
                const resUpload = await axios.post('Resources', resourceData);
                if(resUpload.data && resUpload.data.status === 'success'){
                    const data = {
                        imageOptions: {
                            generateThumbnails: true,
                            thumbnailsSizes: [
                                {
                                    width: 300,
                                    resizeMode: "Auto"
                                }
                            ]
                        }
                    }
                    // -- create thumbnail
                    const resResize = await axios.post(`Resources/${resUpload.data.data}/Options`, data);
                    if(resResize.data && resResize.data.status === 'success') {
                        setUploadingStatus('success');
                        setTimeout(() => {
                            setUploadingStatus('end');
                        }, 1000);
                    } else {
                        setUploadingStatus('fail');
                    }
                    // -- add new image to the top of the list of images
                    seFileToAdd({ file: file, resourceID: resUpload.data.data });
                    // -- add points for uploaded photo
                    axios.get('Achievements/Evaluate/upload_photo');
                } else {
                    setUploadingStatus('fail');
                }
            }
        })
    }

    let modalMessageText = '';
    let modalMessageIcon: 'info' | 'success' | 'error' = 'info';
    switch(uploadingStatus){
        case 'progress': 
            modalMessageText = 'Image upload in progress. Please wait...';
            modalMessageIcon = 'info';
            break;
        case 'success': 
            modalMessageText = 'Image has been uploaded successfully!';
            modalMessageIcon = 'success';
            break;
        case 'fail': 
            modalMessageText = 'An error has occurred while uploading the image. Please check your internet connection or try again later.';
            modalMessageIcon = 'error';
            break;
    }

    return (
        <>
            <Modal size="xl" className="gallery-modal p-0" show={isVisible} centered 
                onHide={fnHideModal}>
                <Modal.Header closeButton>
                    <h1 className="u-font-size-18 w-100 text-center mb-0">Photo gallery</h1>
                    <Button 
                        withClass={["blue"]} classes="modal-header-add" 
                        icon="faPlus" iconPosition="left"
                        clicked={addPhoto}
                    >Add photo</Button>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Gallery newImageToAdd={fileToAdd} />
                </Modal.Body>
            </Modal>

            {/* take a picture window */}
            {
                isTakePictureVisible &&
                <TakePictureModal getImageFile={uploadPhoto} showPortrait={false} />
            }

            {/* show uploading picture window */}
            {
                uploadingStatus !== 'end' &&
                <ModalMessage
                    title="File upload"
                    text={modalMessageText}
                    type={modalMessageIcon}
                    buttons={[]}
                />
            }
        </>
    )

}

export default GalleryModal;
import './config/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import history from './config/history';
import './config/globalFunctions';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './main.scss';

const app = (
    <Router history={history}>
        <App />
    </Router>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();

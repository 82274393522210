import React, { useEffect } from 'react';
import { Confetto } from './Confetto';

// -- params
const confettiParams = {
    // number of confetti per "explosion"
    number: 100,
    // min and max size for each rectangle
    size: { x: [5, 20], y: [10, 18] },
    // power of explosion
    initSpeed: 25,
    // defines how fast particles go down after blast-off
    gravity: 0.65,
    // how wide is explosion
    drag: 0.08,
    // how slow particles are falling
    terminalVelocity: 6,
    // how fast particles are rotating around themselves
    flipSpeed: 0.017,
};
const colors = [
    // { front : '#3B870A', back: '#235106' },
    // { front : '#B96300', back: '#6f3b00' },
    // { front : '#E23D34', back: '#88251f' },
    // { front : '#CD3168', back: '#7b1d3e' },
    // { front : '#664E8B', back: '#3d2f53' },
    // { front : '#394F78', back: '#222f48' },
    // { front : '#008A8A', back: '#005353' },
    { front : '#ffd700', back: '#998100' },
    { front : '#e5c100', back: '#7f6b00' },
    { front : '#ccac00', back: '#665600' },
	{ front : '#b29600', back: '#4c4000' },
];

// global variables
const msfadeTimeout = 2000;
let confetti: HTMLCanvasElement;
let confettiCtx: CanvasRenderingContext2D;
let container: { w: number, h:number }; 
let confettiElements: any[] = [];
let clickPosition: number[];
let myRequestAnimationFrame: number;
let interval: any;

interface IProps {
    isHiding?: boolean,
    fnHide: () => void
}


const Confetti = ({ fnHide, isHiding = false }: IProps) => {

    useEffect(() => {

        confetti = document.getElementById('confetti') as HTMLCanvasElement;
        confettiCtx = confetti.getContext('2d') as CanvasRenderingContext2D;
        
        setupCanvas();
        updateConfetti();
        confettiLoop();

        confetti.addEventListener('click', () => {
            confetti.classList.add('fade-out');
            setTimeout(hideConfetti, msfadeTimeout);
        });

        window.addEventListener('resize', () => {
            clearInterval(interval);
            setupCanvas();
            confettiElements = [];
            window.cancelAnimationFrame(myRequestAnimationFrame);
            updateConfetti();
            confettiLoop();
        });

        // #IMPORTANT: Clear animation on unmount
        return () => {
            clearInterval(interval);
            window.cancelAnimationFrame(myRequestAnimationFrame);
        }

    }, [])

    useEffect(() => {
        if(isHiding){
            setTimeout(hideConfetti, msfadeTimeout);
        }
    }, [isHiding])


    function setupCanvas() {
        container = {
            w: confetti.clientWidth,
            h: confetti.clientHeight
        };
        confetti.width = container.w;
        confetti.height = container.h;
    }

    function updateConfetti () {
        confettiCtx.clearRect(0, 0, container.w, container.h);
    
        confettiElements.forEach((c) => {
            c.update();
            confettiCtx.translate(c.position.x, c.position.y);
            confettiCtx.rotate(c.rotation);
            const width = (c.dimensions.x * c.scale.x);
            const height = (c.dimensions.y * c.scale.y);
            confettiCtx.fillStyle = c.color;
            confettiCtx.fillRect(-0.5 * width, -0.5 * height, width, height);
            confettiCtx.setTransform(1, 0, 0, 1, 0, 0)
        });
    
        confettiElements.forEach((c, idx) => {
            if (c.position.y > container.h ||
                c.position.x < -0.5 * container.w ||
                c.position.x > 1.5 * container.w) {
                confettiElements.splice(idx, 1)
            }
        });
        myRequestAnimationFrame = window.requestAnimationFrame(updateConfetti);
    }

    function confettiLoop() {
        interval = setInterval(addConfetti, 500 + Math.random() * 500)
    }

    function addConfetti(e?: any) {
        const canvasBox = confetti.getBoundingClientRect();
        if (e) {
            clickPosition = [
                e.clientX - canvasBox.left,
                e.clientY - canvasBox.top
            ];
        } else {
            clickPosition = [
                canvasBox.width * Math.random(),
                canvasBox.height * Math.random()
            ];
        }
        for (let i = 0; i < confettiParams.number; i++) {
            confettiElements.push(new Confetto(clickPosition, container, confettiParams, colors))
        }
    }

    function hideConfetti() {
        clearInterval(interval);
        confettiElements = [];
        window.cancelAnimationFrame(myRequestAnimationFrame);
        fnHide();
    }

    return (
        <>
            <canvas id="confetti" className={isHiding ? 'fade-out' : ''}></canvas>
            <audio autoPlay id="playAudio">
                <source src="assets/audio/applausi-low.mp3" />
            </audio>
        </>
    )
}

export default Confetti;
interface IColor {
    front: string,
    back: string
}

interface IConfettiParams {
    // number of confetti per "explosion"
    number: number,
    // min and max size for each rectangle
    size: { x: number[], y: number[] },
    // power of explosion
    initSpeed: number,
    // defines how fast particles go down after blast-off
    gravity: number,
    // how wide is explosion
    drag: number,
    // how slow particles are falling
    terminalVelocity: number,
    // how fast particles are rotating around themselves
    flipSpeed: number,
}

export class Confetto {

    randomModifier: number;
    colorPair: { front: string; back: string; };
    color: string;
    dimensions: { x: number, y: number };
    position: { x: number, y: number };
    rotation: number;
    scale: { x: number, y: number };
    velocity: { x: number, y: number };
    flipSpeed: number;
    terminalVelocity: number;
    update: any;

    constructor(clickPosition: number[], container: { w: number, h: number }, confettiParams: IConfettiParams, colors: IColor[]) {
        this.randomModifier = this.rand(-1, 1);
        this.colorPair = colors[Math.floor(this.rand(0, colors.length))];
        this.color = this.colorPair.front;
        this.dimensions = {
            x: this.rand(confettiParams.size.x[0], confettiParams.size.x[1]),
            y: this.rand(confettiParams.size.y[0], confettiParams.size.y[1]),
        };
        this.position = {
            x: clickPosition[0],
            y: clickPosition[1]
        };
        this.rotation = this.rand(0, 2 * Math.PI);
        this.scale = { x: 1, y: 1 };
        this.velocity = {
            x: this.rand(-confettiParams.initSpeed, confettiParams.initSpeed) * 0.4,
            y: this.rand(-confettiParams.initSpeed, confettiParams.initSpeed)
        };
        this.flipSpeed = this.rand(0.2, 1.5) * confettiParams.flipSpeed;
    
        if (this.position.y <= container.h) {
            this.velocity.y = -Math.abs(this.velocity.y);
        }
    
        this.terminalVelocity = this.rand(1, 1.5) * confettiParams.terminalVelocity;
    
        this.update = function () {
            this.velocity.x *= 0.98;
            this.position.x += this.velocity.x;
    
            this.velocity.y += (this.randomModifier * confettiParams.drag);
            this.velocity.y += confettiParams.gravity;
            this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
            this.position.y += this.velocity.y;
    
            this.scale.y = Math.cos((this.position.y + this.randomModifier) * this.flipSpeed);
            this.color = this.scale.y > 0 ? this.colorPair.front : this.colorPair.back;
        }
    }

    // helper
    rand = (min: number, max: number): number => Math.random() * (max - min) + min;

}
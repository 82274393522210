export default class MemberLog {
    page?:string;
    action?:string;
    section?:string;
    subSection?:string;
    elementId?:string;
    agent?:string;

    constructor(page:string, action:string, section:string, subsection:string, elementId:string, agent:string){
        this.page = page;
        this.action = action;
        this.section = section;
        this.subSection = subsection;
        this.elementId = elementId;
        this.agent = agent;
    }
}
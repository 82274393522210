import React, { useEffect, useContext, useState } from 'react';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import Button from '../_UI/Button/Button';
import Interprefy from '../Interprefy/Interprefy';


const LivePageVideo = () => {

    const { language } = useContext(ContextAppSettings);

    const [isInterprefyActive, setInterprefyActive] = useState<boolean>(false);
    // const [isInterprefyActive, setInterprefy] = useState<boolean>(false);
    const [isInterprefyVisible, setInterprefyVisibility] = useState<boolean>(false);

    useEffect(() => {
        // -- save log
        // axiosInstance.get('Contents/heidelberger-uni-knie-kurs-online');
        console.log("LivePageVideo - childComponentsLoaded");
        window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
    }, [])

    return (
        <section className="live-page-video-wrapper">
            <div className="live-page-video">
                {/* <h2 className="live-page-video__title u-font-size-18">EMEA Town Hall – 2021 Kick Off</h2>
                <h3 className="live-page-video__subtitle u-font-size-12">10 February, 10:30am – 12:00pm</h3> */}
                <div className="live-page-video__frame-wrapper container-video container-vm-iframe">
                <iframe id="ls_embed_1629270869" src="https://livestream.com/accounts/22298103/events/9806963/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1629270869" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
                </div>
                <div className="live-page-video__translate">
                    {/* <Button 
                        icon="faFile" 
                        iconPosition="left" 
                        withClass={["secondary"]}
                        classes="u-font-size-10 font-weight-bold mt-2"
                        clicked={() => window.open('/assets/docs/program-emea-town-hall-2021.pdf', '_blank')}
                        >{ translations.video.program[language] }
                    </Button> */}
                    {
                        !isInterprefyActive ?
                        <Button 
                            icon="faGlobeEurope" 
                            iconPosition="left" 
                            withClass={["secondary"]}
                            classes="u-font-size-10 font-weight-bold mt-2"
                            clicked={() => { setInterprefyActive(true); setInterprefyVisibility(true); }}
                            >Load interpreter
                        </Button> :
                         <div className="live-page-video__interprefy-wrapper col-lg-4 mt-lg-0 mt-3">
                         <div className="live-page-video__interprefy-width"></div>
                         <div className="live-page-video__interprefy-buttons">
                             {
                                 isInterprefyVisible ?
                                 <button className="btn-interprefy btn-hide" onClick={() => setInterprefyVisibility(false)}>HIDE</button> :
                                 <button className="btn-interprefy btn-show" onClick={() => setInterprefyVisibility(true)}>SHOW</button>
                             }
                             <button className="btn-interprefy btn-stop" onClick={() => setInterprefyActive(false)}>STOP</button>
                         </div>
                         <div className={"live-page-video__interprefy" + (isInterprefyVisible ? '': ' d-none')}>
                             <Interprefy />
                         </div>
                     </div>
                    }
                </div>
            </div>
        </section>
    )
    
}


export default LivePageVideo;




import React from 'react';

export type TMainTemplate = 'BeforeEvent' | 'TemplateOne' | 'TemplateTwo' | 'TemplateThree';
export type TMainLanguage = 'en' | 'de';

export interface IAppSettings {
    language: TMainLanguage,
    template: TMainTemplate
}

const initAppSettings: IAppSettings = {
    language: 'en',
    template: 'BeforeEvent'
}

export const ContextAppSettings = React.createContext(initAppSettings);
import React, { useEffect, useContext, useState } from 'react';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { showLoader, hideLoader } from '../../config/functions';

import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import Button from '../../Components/_UI/Button/Button';
import EventHeader from '../../Components/EventHeader/EventHeader';
import Countdown from '../../Components/Countdown/Countdown';
import AddToCalendar from '../../Components/AddToCalendar/AddToCalendar';
import Documents from '../../Components/LivePage/LivePageDocuments';
import WarningIE from '../../Components/WarningIE/Warning';
import Slides from '../Slides/Slides';


interface IProps {

}

var childComponentsLoaded = 0;
var childComponentsToLoad = 1;

const LivePageBeforeEvent = () => {

    useEffect(() => {
        // showLoader();    No async loads
    }, []);

    // When all child components are loaded show the page:
    // - DOCS
    window.addEventListener('childComponentsLoaded', () => {
        childComponentsLoaded++;
        if (childComponentsLoaded >= childComponentsToLoad) {
            setTimeout(() => hideLoader(), 500);
        }
    }, false)


    const { language } = useContext(ContextAppSettings);
    // const [showWarningIE, setShowWarningIE] = useState(true);
    // const onClickShowWarning = () => setShowWarningIE(!showWarningIE);


    return (
        <div className="live-page-wrapper container-fluid">
            <main className="live-page live-page--registration-only my-md-5 my-4">
                <div className="live-page-reg-header">
                    <EventHeader />
                </div>
                <div className="live-page-reg-countdown">
                    <Countdown />
                </div>
                {/* <div className="live-page-reg-program">
                    <Button 
                        icon="faFile" 
                        iconPosition="left" 
                        withClass={["secondary","center"]}
                        classes="u-font-size-12 font-weight-bold"
                        clicked={() => window.open('/assets/docs/program-emea-town-hall-2021.pdf', '_blank')}
                    >{ translations.event.program[language] }
                    </Button>
                </div> */}
                <div className="live-page-reg-paragraph">
                    <hr className="live-page-reg-paragraph-separator" />
                    {/* <p className="text-center u-font-size-12">
                        <b>Recognized. Aligned. Inspired.</b><br/>
                        We are looking forward to gathering with you <br/>
                        for the EMEA Town Hall.
                    </p> */}
                    {/* <hr className="live-page-reg-paragraph-separator" /> */}
                </div>
                <div className="live-page-reg-calendar">
                    <AddToCalendar />
                </div>
                {/* <div className="live-page-reg-documents">
                    <Documents />
                </div> */}
                {/*
                <div className="live-page-reg-slides">
                    <Slides />
                </div>
                */}
            </main>
        </div>
    )

}

export default LivePageBeforeEvent;
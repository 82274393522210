import React, { useEffect } from 'react';

interface IProps {
    imgSource: string,
    title: string,
    subtitle?: string,
}

const LabelledIcon = ({ imgSource, title, subtitle }: IProps) => {

    useEffect(() => {

    }, [])

    return (
        <header className="befor-page-section-header">
            <img className="before-page-section-header__icon" src={imgSource} alt="icon" />
            <h2 className="before-page-section-header__title u-font-size-8">{ title }</h2>
            {/* <hr className="before-page-section-header__separator" />
            { 
                subtitle && 
                <h3 className="live-page-section-header__title u-font-size-10">{ subtitle }</h3> 
            } */}
        </header>
    )

}

export default LabelledIcon;
import React from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom";
import axios from './config/axios';
import axiosModule from 'axios';
import history from './config/history'; 
import { TMainLanguage, TMainTemplate, IAppSettings, ContextAppSettings } from './config/context';

import { MembersTypeRes } from './Models/ResponseModels/Members';
import PageNotFound from './Components/_UI/ErrorHandler/PageNotFound';
import PageForbidden from './Components/_UI/ErrorHandler/PageForbidden';
import PrivateRoute from './Components/_UI/PrivateRoute';
import Navigation from './Components/Navigation/Navigation';
import MemberLog from './Models/Log/MemberLog';
import { saveLog, showErrorMessage, showInfoMessage, showSuccessMessage, showWarningMessage } from './config/functions';
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";

// Login
import LoginPage from './Containers/LoginPage/LoginPage';
import TermsOfService from './Components/LoginPage/TermsOfService';
import ForgotPassword from './Containers/ForgotPassword/ForgotPassword';
import ChangePassword from './Containers/ForgotPassword/ChangePassword';
import RegisterPage from './Containers/RegisterPage/RegisterPage';
import RegisterSuccess from './Components/RegisterPage/RegisterSuccess';

// Page live interactive
import LivePageUserContainer from './Containers/LivePage/LivePageUserContainer';

// Chat
import Chat from './Containers/Chat/Chat';

// Footer
import Footer from './Components/Footer/Footer';
import LivePageTemplateThree from './Containers/LivePage/LivePageTemplateThree';


// Footer legal pages
const LegalNotice = React.lazy(() => import('./Components/Legal/LegalNotice'));
const TermsService = React.lazy(() => import('./Components/Legal/TermsService'));
const PrivacyPolicy = React.lazy(() => import('./Components/Legal/PrivacyPolicy'));
const CookiesNotice = React.lazy(() => import('./Components/Legal/CookiesNotice'));

// Survey evaluation form
const Survey = React.lazy(() => import('./Components/Survey/Survey'));

// Faculty panel
const FacultyPanel = React.lazy(() => import('./Containers/FacultyPanel/FacultyPanel'));

// Users list
const UsersList = React.lazy(() => import('./Containers/UsersList/UsersList'));

// Events - edit event active
const EventAdmin = React.lazy(() => import('./Containers/Event/EventAdmin'));

// File viewers
const VideoViewer = React.lazy(() => import('./Components/FileViewer/VideoViewer'));


// declare global {
//   interface Window {
//     idbCustom: any;
//   }
// }

interface IProps {
  location?: string
}

interface IState {
  authToken: string | null,
  myProfileData: MembersTypeRes.IMember | null,
  myGroupData: MembersTypeRes.IGroupAssociation | null,
  apiKeepAliveTO: any,
  wsKeepAliveTO: any,
  isNavVisible: boolean,
  isRegistrationEnabled: boolean,                 // true: register visible, on login goes to edit profile, false: no register, on login goes to landing
  mainTemplate: TMainTemplate,
  mainLanguage: TMainLanguage
}


class App extends React.Component<IProps,IState> {

  state: IState = {
    authToken: window.storageGetItemValue('Auth-token'),
    myProfileData: null,
    myGroupData: null,
    apiKeepAliveTO: null,
    wsKeepAliveTO: null,
    isNavVisible: true,
    isRegistrationEnabled: true,
    mainTemplate: 'BeforeEvent',
    mainLanguage: 'en'
  }

  componentWillMount() {
    //Environment conditional settings
    window.webSocket = null;
    window.wsAttempts = 0;

    if(process.env.NODE_ENV === 'development')
    {
      // window.apiURL = 'http://localhost:55158/api/';
      // window.wsURL = 'ws://127.0.0.1:8080';
      window.apiURL = 'https://api.zbemeapulse.com/api/';
      window.wsURL = 'wss://www.zbemeapulse.com/ws_zbemeapulse';
      axios.defaults.baseURL = window.apiURL;
      window.debug = true;
      window.sessionLength = 1;
      window.chatRoomLive = "9";
      window.chatRoomBackstage = "10";
    }
    else if(process.env.REACT_APP_STATE === 'test')
    {
      window.apiURL = 'https://api.zbemeapulse.com/api/';
      window.wsURL = 'wss://www.zbemeapulse.com/ws_zbemeapulse';
      axios.defaults.baseURL = window.apiURL;
      window.debug = false;
      window.sessionLength = 20;
      window.chatRoomLive = "9";
      window.chatRoomBackstage = "10";
    }
    else if(process.env.REACT_APP_STATE === 'testDB2')
    {
      window.apiURL = 'https://api.zbemeapulse.com/api/';
      window.wsURL = 'wss://www.zbemeapulse.com/ws_zbemeapulse';
      axios.defaults.baseURL = window.apiURL;
      window.debug = false;
      window.sessionLength = 20;
      window.chatRoomLive = "9";
      window.chatRoomBackstage = "10";
    }
    else //proid
    {
      window.apiURL = 'https://api.zbemeapulse.com/api/';
      window.wsURL = 'wss://www.zbemeapulse.com/ws_zbemeapulse';
      axios.defaults.baseURL = window.apiURL;
      window.debug = false;
      window.sessionLength = 20;
      window.chatRoomLive = "9";
      window.chatRoomBackstage = "10";
    }

    //this state is muted directly because don't have to cause components refreshes
    this.state.apiKeepAliveTO = setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000);

    // this.setState({ apiKeepAliveTO: setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000) })
  }

  apiKeepAlive = () => {
    const token = window.storageGetItemValue("Auth-token");
    if(window.debug) console.log("App.tsx","APIKeepAlive called (due inactivity on api calls)")
    if(token != null)
    {

      var dateDiff = Date.now() - window.lastApiRequest;
      
      if(dateDiff > window.sessionLength * 60 * 1000)
      {
        if(window.debug) console.log("App.tsx","API KeepAlive call - Changing token!");
        axios.get('Authentication/TokenRefresh');
      }
    }

    //updated manually (and not thorugh this.setState) because it doesn't have to cause components refresh!
    this.state.apiKeepAliveTO = setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000);
    //this.setState({ apiKeepAliveTO: setTimeout(this.apiKeepAlive, window.sessionLength * 60 * 1000) })

  }

  componentDidMount(){

    if(window.storageGetItemValue("version") !== 4){
      window.storageSetItem("version",4);
      window.location.reload();
    }

    // -- get settings to know which sections have been enabled
    // TODO
    // axios.get('Settings').then(res => {
    //   const response = res.data;
    //   if(response.status === 'success'){
    //     const data = response.data;
    //     this.setState({
    //       isRegistrationEnabled: data.EnableRegistration,
    //     })
    //   }
    // })

    if(this.state.authToken !== null){

      var newLog:MemberLog = new MemberLog("/", "OpeningPage","EMEA Town Hall – 2021-09-02","Enter","", navigator.userAgent);
      saveLog(newLog);

      axios.get('Members/GetMyProfile').then(res => {
        const profileResponse = res.data;
        if(profileResponse.status === "success")
        {
          const myProfileData: MembersTypeRes.IMember = profileResponse.data;
          this.setState({ myProfileData: myProfileData });
        } else {
          this.handleAuthorization(null)
        }
      }).catch(e => console.log(e));

      window.addEventListener("beforeunload", this.beforeUnload);

    }

  }

  componentWillUnmount() {
    //removing onbeforeunload:
    window.removeEventListener("beforeunload", this.beforeUnload);
  }

  beforeUnload = () => {
    var token = window.storageGetItemValue("Auth-token");
    if(token)
    {
      var newLog:MemberLog = new MemberLog("/", "LeavingPage","EMEA Town Hall – 2021-09-02","Leave","", navigator.userAgent);
      saveLog(newLog,false);
      }
  };

  checkWebSocketConnection = () => {
    if(window.debug) console.log("App.tsx","checkWebSocketConnection");
    if(this.state.authToken !== null ){
      if(window.webSocket === null || window.webSocket.readyState !== 1){
        window.location.reload();
      }
    }
  }

  handleAuthorization = async (token: string | null) => {

    // this.setState({ authToken: token }, async () => {
      if(token) {
        // return to previous page opened before to be re-rendered (only if not in home page)
        // checking if i have previously visited a page
        this.setState({ authToken: token }, () => {
          const redirectTo = window.storageGetItemValue("redirectTo");
          if(redirectTo){
            // Used timeout -> TODO: check why is not going there without timeout
            setTimeout(() => history.push(redirectTo), 500);
            window.storageRemoveItem("redirectTo");
          } else {
            if(history.location.pathname.includes('/login') || history.location.pathname.includes('/change-password')){
              history.push("/")
            }
          }
        });
      }
      else {
        // logout, removing stored device token for current user
        if(history.location.pathname !== '/change-password/'){
          const token = window.storageGetItemValue("Auth-token");
          const data = {}
          const config = { 
            headers: { Authorization: token }
          }
          try {
            var newLog:MemberLog = new MemberLog("/", "LoggedOut","EMEA Town Hall – 2021-09-02","Login","", navigator.userAgent);
            saveLog(newLog);

            const res = await axiosModule.post(window.apiURL+"Authentication/logout", data, config);
            setTimeout(() => { window.removeStoredUserData(); this.setState({ authToken: null }); }, 200);
          } catch(e){ 
            setTimeout(() => { window.removeStoredUserData(); this.setState({ authToken: null }); }, 200);
          }
        }
      }
    // })
      
  }

  handleShowNav = (isVisible: boolean) => {
    this.setState({ isNavVisible: isVisible });
  }

  setProfileData = (profileData: MembersTypeRes.IMember) => {
    this.setState({ myProfileData: profileData });
  }

  setGroupData = (groupData: MembersTypeRes.IGroupAssociation) => {
    this.setState({ myGroupData: groupData });
  }

  render() {

    const { authToken, myProfileData, myGroupData, isNavVisible, isRegistrationEnabled, mainTemplate } = this.state;
    const AppSettings: IAppSettings = {
      language: this.state.mainLanguage,
      template: this.state.mainTemplate
    }

    const isBeforeEventTemplateEnabled = this.state.mainTemplate === 'BeforeEvent'

    return (
      <div className="app">
        <ContextAppSettings.Provider value={AppSettings}>
          { authToken && window.storageGetItemValue("Auth-token") !== null && myProfileData && isNavVisible &&
            <Navigation setToken={this.handleAuthorization} userData={myProfileData} groupData={myGroupData} /> 
          }
          {/* {
            isBeforeEventTemplateEnabled &&
            <nav className="navigation container-fluid" id="navigation">
              <Link to="/">
                  <img src="/assets/images/logo-zimmer-biomet.png" className="navigation-logo img-fluid" alt="zimmer biomet"/>
              </Link>
            </nav>
          } */}
          <React.Suspense fallback={<div className="page-loader"></div>}>
            <Switch>
              {/* {
                isRegistrationEnabled && !authToken && !isBeforeEventTemplateEnabled &&
                <Route path="/" exact component={() => 
                    <RegisterPage type='new' isRegistrationEnabled={true}></RegisterPage>
                  } 
                />
              } */}
              {
                isRegistrationEnabled && !authToken &&
                <Route path="/" exact component={() => 
                    <RegisterPage type='new' isRegistrationEnabled={true}></RegisterPage>
                  } 
                />
              }
              <PrivateRoute path="/" exact Component={LivePageUserContainer} 
                Props={{ 
                  authToken: authToken, 
                  userData: myProfileData, 
                  groupData: myGroupData, 
                  chatRoomID: window.chatRoom,
                  isPlaylistEnabled: false,
                  mainTemplate: mainTemplate
                }} 
              />
                <PrivateRoute path="/live-page" exact Component={LivePageUserContainer} 
                Props={{ 
                  authToken: authToken, 
                  userData: myProfileData, 
                  groupData: myGroupData, 
                  chatRoomID: window.chatRoom,
                  isPlaylistEnabled: false,
                  mainTemplate: 'TemplateThree'
                }} 
              />
              {/* {
                !isBeforeEventTemplateEnabled && 
                <PrivateRoute path="/" exact Component={LivePageUserContainer} 
                  Props={{ 
                    authToken: authToken, 
                    userData: myProfileData, 
                    groupData: myGroupData, 
                    chatRoomID: window.chatRoom,
                    isPlaylistEnabled: false,
                    mainTemplate: mainTemplate
                  }} 
                />
              }
              {
                isRegistrationEnabled && isBeforeEventTemplateEnabled &&
                <Route path="/" exact component={() => 
                    <LivePageUserContainer 
                      userData={myProfileData} 
                      groupData={myGroupData}
                      chatRoomID={window.chatRoom}
                      isPlaylistEnabled={false}
                      mainTemplate="BeforeEvent"/>
                  } 
                />
              } */}
              <Route path="/login" exact component={() => 
                <LoginPage 
                  setToken={this.handleAuthorization} 
                  setProfile={this.setProfileData} 
                  setGroup={this.setGroupData} 
                  isRegistrationActive={isRegistrationEnabled}
                />} 
              />
              { isRegistrationEnabled && <Route path="/register" exact component={() => <RegisterPage type='new' isRegistrationEnabled={true}></RegisterPage>} /> }
              { 
                isRegistrationEnabled && 
                <Route path="/register-success" exact component={() => 
                  <RegisterSuccess type="reg" />} 
                /> 
              }
              <Route path="/edit-profile-success" exact component={() => <RegisterSuccess type="edit" />} />
              <Route path="/forgot-password-success" exact component={() => <RegisterSuccess type="forgot-pwd" />} />
              <Route path="/change-password-success" exact component={() => <RegisterSuccess type="change-pwd" />} />
              <PrivateRoute path="/update-my-info" exact Component={RegisterPage} Props={{ authToken: authToken, type: 'edit', isRegistrationEnabled: isRegistrationEnabled }} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/change-password" exact component={ChangePassword} />
              <PrivateRoute path='/chat-admin/:key' exact Component={Chat} Props={{ authToken: authToken, showTextArea: false, maxMsgLoaded: 10, liveWebinar: true }} />
              <PrivateRoute path="/events-admin" exact Component={EventAdmin} Props={{ authToken: authToken }} />
              <PrivateRoute path="/file/:key" exact Component={VideoViewer} Props={{ authToken: authToken }} />

              {/* user management */}
              <PrivateRoute path="/users-list" Component={UsersList} Props={{ authToken: authToken }} />

              {/* faculty panel page */}
              <PrivateRoute path="/faculty-panel" Component={FacultyPanel} Props={{ authToken: authToken }} />

              {/* survey evaluation form */}
              <PrivateRoute path='/evaluation/:key' Component={Survey} Props={{ authToken: authToken }} />

              {/* legal content */}
              <Route path="/legal-notice"><LegalNotice /></Route>
              <Route path="/terms-of-service"><TermsService /></Route>
              <Route path="/privacy-policy"><PrivacyPolicy /></Route>
              <Route path="/cookies-notice"><CookiesNotice /></Route>

              <Route component={PageNotFound} />
            </Switch>

          </React.Suspense>

          {/* footer  */}
          <Footer />

        </ContextAppSettings.Provider>
        
        <ToastContainer />

      </div>
    )

  }

}

export default App;

import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from '../../config/axios';
import history from '../../config/history';
import { validateEmail, hideLoader } from '../../config/functions';

import Button from '../../Components/_UI/Button/Button';
import EventHeader from '../../Components/EventHeader/EventHeader';
import Countdown from '../../Components/Countdown/Countdown';


const ForgotPasswordPage = () => {

    const [statusError, setStatusError] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);

    useEffect(() => {
        hideLoader();
    }, [])

    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const handleSubmit = (values: { email: string }) => {
        setStatusError(0);
        setError('');
        setSendingEmail(true);
        const data = { 
            email: values.email,
        }
        axios.post('Members/SendEmailRetrievePassword', data).then(res => {
            if(res.data.status === "success"){
                setStatusError(200);
                setSendingEmail(false);
                setTimeout(() => history.push('/forgot-password-success'), 500);
            } else {
                if(res.data.message){
                    const errorMessage = (res.data.message.indexOf('disabled') >= 0 ? 
                    'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                    res.data.message)
                    setError(errorMessage);
                } else {
                    setStatusError(404);
                }
            }
        }).catch(e => {
            const response = e.response;
            if(response.data.status === "error"){
                const errorMessage = (response.data.message.indexOf('disabled') >= 0 ? 
                'Forgot the password? Please note that your account is pending approval by a Zimmer Biomet Admin. Once approved, you will receive a confirmation email. In the meantime, you will be unable to access the platform or recover your password. Thank you for your patience and understanding.' :
                response.data.message)
                setError(errorMessage)
            } else {
                setError("An error has occurred. Please try again later.")
                // setStatusError(e.response ? e.response.status : 404);
            }
            setSendingEmail(false);
        });
    }

    const formValidateEmail = (email: string) => {
        let error;
        if (!validateEmail(email))
            error = 'Invalid email';
        return error;
    }

    return (
        <div className="forgot-password interactive-page container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="interactive-page-login-header">
                        <EventHeader />
                    </div>
                    <div className="interactive-page-login-countdown">
                        <Countdown />
                    </div>
                    <main className="interactive-page-form-wrapper">
                        <Formik
                            initialValues={{
                                password: '',
                                email: '',
                            }}
                            onSubmit={values => {
                                handleSubmit(values);
                            }}
                        >
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form className="interactive-page-form">
                                    <div className="interactive-page-form-content">
                                        <h3 className="interactive-page-form__title">Forgot your password?</h3>
                                        <h4 className="interactive-page-form__subtitle">Please insert your email, a link to change your password will be sent to your inbox.</h4>
                                        <hr className="interactive-page-form__separator" />
                                        {/* email */}
                                        <div className="interactive-page-form__group" >
                                            <Field 
                                                name="email" 
                                                type="email"
                                                className="form-control interactive-page-form__field"
                                                onChange={(e: any) => { setFieldValue('email', e.target.value.toLowerCase()) }} 
                                                validate={() => formValidateEmail(values.email)} 
                                                placeholder="E-mail"
                                            />
                                            <div className={`invalid-feedback u-font-size-10 ${errors.email && touched.email ? "d-block" : ""}`}>
                                                {errors.email}
                                            </div>
                                        </div>
                                        {/* submit button */}
                                        <Button type="submit"
                                            withClass={["primary","center"]}
                                            classes="interactive-page-form__submit u-font-size-12 font-weight-bold mt-md-5 mt-4"
                                            disabled={sendingEmail}
                                        >SEND ME AN EMAIL WITH THE LINK</Button>
                                        { statusError === 200 && <div className="valid-feedback u-font-size-9 d-block">An email has been sent</div> }
                                        { statusError === 401 && <div className="invalid-feedback u-font-size-9 d-block">Unauthorized</div> }
                                        { statusError === 403 && <div className="invalid-feedback u-font-size-9 d-block">Forbidden</div> }
                                        { statusError === 404 && <div className="invalid-feedback u-font-size-9 d-block">Network error. Please check your internet connection and try again.</div> }
                                        { ![0,200,401,403,404].includes(statusError) && 
                                            <div className="invalid-feedback u-font-size-9 d-block">An error has occurred. Please try again later.</div> }
                                        <div className={`invalid-feedback u-font-size-9 ${error.length > 0 ? "d-block" : ""}`}>
                                            {error}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </main>
                    {/* back button */}
                    <Button type="submit"
                        withClass={["secondary","center"]}
                        classes="interactive-page-back u-font-size-12 font-weight-bold mt-md-5 mt-4"
                        clicked={() => history.push('/')}
                    >&lt; BACK</Button>
                </div>
            </div>
        </div>
    )

}

export default ForgotPasswordPage;
// #IMPORTANT
// Get user and pass saved as glob vars, decrypt and use them to refresh token

import axios from 'axios';
import { isIE } from '../config/functions';
import history from './history';

var CryptoJS = require("crypto-js");


const axiosInstance = axios.create({
    baseURL: window.apiURL
});

const axiosRetryApiCall = axios.create({
  baseURL: window.apiURL
});

axiosInstance.interceptors.request.use(async (request) => {
  // Do something with request data
  // console.log("request - SUCCESS", request);
  const token = window.storageGetItemValue("Auth-token");
  request.headers.Authorization = token ? token : ''
  if(isIE()){
    const url = request.url;
    request.url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'id='+new Date().getTime()
  }
  return request;
  // use an external js class to save useremail and password for refreshing the token
}, function (error) {
  console.log("request - ERROR", error);
  return error;
});

axiosInstance.interceptors.response.use((response) => {
  window.lastApiRequest = Date.now();
  var newToken = response.headers["x-token"];
  if(newToken !== undefined)
  {
    if(newToken.length > 0)
    {
      //if(window.debug) console.log("changing token",newToken);
      window.storageSetItem("Auth-token", newToken);
    }
  }
  // Success response
  return response;
  // Error response
}, async (error) => {
  
    // Try refreshing token
    // if(error && error.response && (error.response.status === 401 || error.response.status === 403)){

    //   let token = window.storageGetItemValue("Auth-token");
    //   const email = window.storageGetItemValue("Auth-email");
    //   const password = window.storageGetItemValue("Auth-password");
  
    //   // If logged
    //   if(token && email && password){
    //     const data = {
    //       userName: CryptoJS.AES.decrypt(email.toString(), '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString(CryptoJS.enc.Utf8), 
    //       password: CryptoJS.AES.decrypt(password.toString(), '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString(CryptoJS.enc.Utf8)
    //     }
    //     const config = { headers: { Authorization: token } }
    //     try {
    //       // refresh token
    //       const resToken = await axios.post(window.apiURL+'Authentication/SessionRefresh', data, config);
    //       console.log(resToken, error, error.config.data, error.config.url);
    //       token = resToken.data.data.token;
    //       window.storageSetItem("Auth-token", token);
    //       window.lastApiRequest = Date.now();
  
    //       // try again the previous api call
    //       const resCall = await axiosRetryApiCall({
    //         method: error.config.method,
    //         url: window.apiURL+error.config.url,
    //         data: error.config.data ? JSON.parse(error.config.data) : null,
    //         headers: {
    //           Authorization: token
    //         }
    //       })
    //       // Success response
    //       return resCall;
    //     } catch(error) {
    //       console.log(error);
    //     }
    //     window.removeStoredUserData();
    //     // Redirect on login page only if i'm not already there and save the url for redirect
    //     if(history.location.pathname !== '/login'){
    //       localStorage.setItem("redirectTo", window.location.pathname);
    //       history.push('/login');
    //     }
    //     return Promise.reject(error);
    //   }
    // }

    // NB: if error 400 (es. registering a member already registered) i don't have to go back to login
    if(error && error.response && (error.response.status === 401 || error.response.status === 403)){
      window.removeStoredUserData();
      // Redirect on login page only if i'm not already there and save the url for redirect
      if(history.location.pathname !== '/login'){
        window.storageSetItem("redirectTo", window.location.pathname+window.location.search, true);
        history.push('/login');
      }
    }
    return Promise.reject(error);
});


export default axiosInstance;
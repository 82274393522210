import React from 'react';
import { match } from "react-router-dom";

import axios from '../../config/axios';
import ConnectionError from '../_UI/ErrorHandler/ConnectionError';
import PageNotFound from '../_UI/ErrorHandler/PageNotFound';
import PageForbidden from '../_UI/ErrorHandler/PageForbidden';
import { showLoader, hideLoader } from '../../config/functions';


interface IApiCall {
    url: string,
    method: "GET"|"POST"|"PUT"|"DELETE",
    data: any
}

interface DetailParams {
    key: string;
}

interface IProps {
    match?: match<any>,
    key?: string,
    additionalProp1?: string,
}

interface IState {
    images: {[key: string]: string},
    response: any,
    isMount: boolean,
    error: number
}

// #IMPORTANT: High order component
// Used to manage first load of a page, with api call and any image needed already cached
// The Component will be rendered only when all tasks are done and it will receive all data returned as props

function loadPage(WrappedComponent: any, apiCall: IApiCall | null = null, images: any = {}, p_Key: string = "", openLoader: boolean = true, closeLoader: boolean = true){

    return class extends React.Component<IProps,IState> {

        constructor(props: any) {
            super(props);
            this.state = {
                images: {},
                response: '',
                isMount: false,
                error: -1
            }
        }
    
        componentDidMount() {

            openLoader && showLoader();
            if(window.debug) console.log(apiCall);

            if(apiCall){
                // #IMPORTANT: create a copy of the api call parameters, replacing any variable
                let localApiCallUrl = apiCall.url;
                let localApiCallData = JSON.stringify(apiCall.data);
                let keyValue = '';
                let keyParam = p_Key.length > 0 ? p_Key : '{KEY}'
                // serch for a specific key name or key
                if(p_Key){
                    if(this.props.match && this.props.match.params[p_Key]){
                        keyValue = this.props.match.params[p_Key]
                    }
                } else {
                    if(this.props.match && this.props.match.params.key){
                        keyValue = this.props.match.params.key;
                    }
                }
                localApiCallUrl = localApiCallUrl.replace(keyParam, keyValue);
                localApiCallData = localApiCallData.replace('"'+keyParam+'"', keyValue);

                axios({
                    method: apiCall.method,
                    url: localApiCallUrl,
                    data: JSON.parse(localApiCallData)
                }).then((res: any) => {
                    if(res.data.status === "success" && res.data.data !== null){
                        // this.loadImagesInCache().then(cachedImages => {
                            this.setState({ response: res, isMount: true }, () => {
                                closeLoader && hideLoader();
                            });
                        // })
                    } else {
                        this.setState({ isMount: true, error: 404 }, () => hideLoader());
                    }
                }).catch(e => {
                    this.setState({ isMount: true, error: e.response ? e.response.status : 0 }, () => hideLoader());
                })
            } else {
                // this.loadImagesInCache().then(cachedImages => {
                    this.setState({ response: '', isMount: true }, () => {
                        closeLoader && hideLoader();
                    });
                // })
            }
        }

        // #IMPORTANT: dynamically add all images to load in cache
        // loadImagesInCache = () => {
        //     const promiseImagesList = Object.keys(images).map(key => {
        //         return window.idbCustom.loadImage(images[key]);
        //     })
        //     return Promise.all(promiseImagesList).then((values: string[]) => {
        //         const cachedImages: any = {};
        //         Object.keys(images).forEach((key, index) => {
        //             cachedImages[key] = values[index];
        //         })
        //         return Promise.resolve(cachedImages);
        //     })
        // }
    
        render() {

            const { images, response, isMount, error } = this.state;

            const resData = response.data ? response.data.data : null

            return (
                isMount ?
                    (error < 0 ?
                        <WrappedComponent images={images} response={response} {...this.props} /> :
                        (error === 0 ? <ConnectionError></ConnectionError> :
                            (error === 403 ? <PageForbidden></PageForbidden> : <PageNotFound></PageNotFound>))
                    ) :
                null
            )
        }
    }

}

export default loadPage;
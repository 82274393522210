import React from 'react';

const Countdown = () => {
    return (
        <div className="event-coutdown-wrapper">
            <div className="event-countdown text-center">
                <p className="event-countdown-title">
                    THE EVENT WILL START IN:
                </p>
                <img src="https://i.countdownmail.com/17ax46.gif" className="img-fluid event-countdown-img" alt="countdown" />
            </div>
        </div>
    )
}

export default Countdown;
import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn, faFacebook } from '@fortawesome/free-brands-svg-icons';


interface Props {
    isHidden: boolean
}
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-company">
                <div className="footer-company-disclaimer container-md">
                    <p className="u-font-size-8 mb-0 footer-company-disclaimer__text">
                        DISCLAIMER: The information shared on this website and during this meeting is intended for Zimmer Biomet team members only. Distribution to and/or sharing with any other recipient is prohibited. All content herein is protected by copyright, trademarks and other intellectual property rights, as applicable, owned by or licensed to Zimmer Biomet or its affiliates unless otherwise indicated, and must not be redistributed, duplicated or disclosed, in whole or in part, without the express written consent of Zimmer Biomet. ©2021 Zimmer Biomet. 
                    </p>
                </div>
                <div className="footer-company-content container-md">
                    <div className="row">
                        <div className="col-6 mb-sm-0 mb-3 footer-company-content-address">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                {/* Zimmer Biomet */}
                                Zimmer GmbH
                            </h1>
                            <p className="u-font-size-8 mb-0">
                                {/* Sulzerallee 8<br/>CH-8404 Winterthur<br/>Switzerland */}
                                Zählerweg 4<br/>CH-6300 Zug<br/>Switzerland
                            </p>
                            <a className="u-font-size-9" href="/assets/docs/zb-privacy-policy-zbemeapulse.pdf" target="_blank">Privacy Policy</a>
                        </div>
                        {/* <div className="col-md-4 col-sm-3 col-6 mb-sm-0 mb-3 footer-company-content-legal">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                Legal
                            </h1>
                            <p className="u-font-size-8 mb-0">
                                <Link to="/legal-notice">Legal Notice</Link><br/>
                                <Link to="/terms-of-service">Terms of Service</Link><br/>
                                <a href="https://www.zimmerbiomet.com/privacy-policy.html" target="blank">
                                    Privacy Policy
                                </a><br/>
                                <Link to="/cookies-notice">Cookies Notice</Link>
                            </p>
                        </div> */}
                        <div className="col-6 footer-company-content-social">
                            <h1 className="u-font-size-10 font-weight-bold mb-2">
                                Connect with us
                            </h1>
                            <p className="u-font-size-8 mb-2">
                                <a href="https://www.facebook.com/zimmerbiomet" target="blank" title="Facebook" className="mr-sm-2 mr-1">
                                    <FontAwesomeIcon className="social-icon facebook" icon={faFacebookF} />
                                </a>
                                <a href="https://twitter.com/zimmerbiomet" target="blank" title="Twitter" className="mr-sm-2 mr-1">
                                    <FontAwesomeIcon className="social-icon twitter" icon={faTwitter} />
                                </a>
                                <a href="http://www.youtube.com/zimmer" target="blank" title="YouTube" className="mr-sm-2 mr-1">
                                    <FontAwesomeIcon className="social-icon youtube" icon={faYoutube} />
                                </a>
                                <a href="https://www.linkedin.com/company/zimmer-biomet-institute-emea" target="blank" title="Linkedin">
                                    <FontAwesomeIcon className="social-icon linkedin" icon={faLinkedinIn} />
                                </a>
                            </p>
                            <p className="u-font-size-8 mb-0">
                                <a href="https://www.zimmerbiomet.eu//" target="blank">www.zimmerbiomet.eu</a><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
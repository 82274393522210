import React from 'react';
import Header from '../../Components/LoginPage/LoginPageHeader';
import Button from '../_UI/Button/Button';
import history from '../../config/history';
import EventHeader from '../EventHeader/EventHeader';
import Countdown from '../Countdown/Countdown';

interface IProps {
    type: string,
}

const RegisterSuccess = ({ type }: IProps) => {

    return (
        <div className="interactive-page-reg-success container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="interactive-page-login-header">
                        <EventHeader />
                    </div>
                    <div className="interactive-page-login-countdown">
                        <Countdown />
                    </div>
                    <main className="interactive-page-reg-success-text">
                        <h1 className="u-font-size-25">
                            { type === 'reg' ? 
                                'Registration completed!' : 
                                (
                                    type === 'edit' ?
                                    'Update profile successful!' :
                                    (
                                        type === 'forgot-pwd' ? 
                                        'Email sent successfully!' :
                                        (
                                            type === 'change-pwd' ? 
                                            'Password change successful!' :
                                            ''
                                        )
                                    )
                                )
                            }
                        </h1>
                        <p className="u-font-size-10">
                            { type === 'reg' ? 
                                <>Thank you</> : 
                                (
                                    type === 'edit' ?
                                    'Your profile has been successfully updated.' :
                                    (
                                        type === 'forgot-pwd' ? 
                                        <>An email with a link to change your password has been sent to your inbox<br/>
                                        <p className="u-font-size-9">If you can't see it, please also check into your spam folder.</p></> :
                                        (
                                            type === 'change-pwd' ? 
                                            'Your profile password has been successfully updated.' :
                                            ''
                                        )
                                    )
                                )
                            }
                        </p>
                        {/* <p className="u-font-size-10">Please <Link to="/login">click here</Link> to login with your account.</p> */}
                    </main>
                    {/* back button */}
                    <Button type="submit"
                        withClass={["secondary","center"]}
                        classes="interactive-page-reg-success-back u-font-size-12 font-weight-bold mb-md-5 mb-4"
                        clicked={() => history.push('/')}
                    >&lt; BACK</Button>
                </div>
            </div>
        </div>
    )

}

export default RegisterSuccess;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import LivePageBeforeEvent from '../../Containers/LivePage/LivePageBeforeEvent';
import LabelledIcon from '../LabelledIcons/LabelledIcons';
import Button from '../_UI/Button/Button';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    clicked?: () => void,
}


const WarningIE = ({clicked}:IProps) => {
    
    return (
        <Container className={"event-warning-wrapper "}>
            <div className="event-warning-close">  
                <FontAwesomeIcon
                icon={faTimes}
                onClick={clicked}
                className="event-warning-close__icon"
                />
            </div>
            <div className="row event-warning-content">
            <LabelledIcon
                    title={ "Internet Explorer" }
                    imgSource="assets/icons/internet-explorer.png"
            />
            </div>
            <div className="row event-warning-content u-font-size-10">
                {/* <div className="event-warning-content"> */}
                    <LabelledIcon
                        title={ "Microsoft Edge" }
                        imgSource="assets/icons/microsoft-edge.png"
                    /><LabelledIcon
                        title={ "Google Chrome" }
                        imgSource="assets/icons/google-chrome.png"
                    /><LabelledIcon
                        title={ "Mozilla Firefox" }
                        imgSource="assets/icons/mozilla-firefox.png"
                    /><LabelledIcon
                        title={ "Safari" }
                        imgSource="assets/icons/safari.png"
                    />
                    <LabelledIcon
                        title={ "Opera" }
                        imgSource="assets/icons/opera.png"
                    />
                </div>
            {/* </div> */}
                <div className="event-warning text-center">
                    <p className="event-warning-title u-font-size-10">
                        <b>Internet Explorer is not supported by this webpage.</b><br/>
                        Please change your browser to one of the applications listed above. <br/><br/>
                        If not yet installed on your device, you can download them from their related websites for free.
                    </p>
                </div>
        </Container>
    )
}

export default WarningIE;
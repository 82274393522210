import React, { useEffect, useState } from 'react';
import FilerobotImageEditor from 'filerobot-image-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faImage } from '@fortawesome/free-regular-svg-icons';
import { faPause } from '@fortawesome/free-solid-svg-icons';

import { fileToBase64, dataURLtoFile, isIE } from '../../config/functions';
import { Modal } from 'react-bootstrap';
import Button from '../_UI/Button/Button';
import axiosInstance from '../../config/axios';

interface IProps {
    showPortrait?: boolean,
    getImageFile: (file: File | null) => void,
}

interface IState {
    activeTab: number,
    isPlayButtonVisible: boolean,
    isPauseButtonVisible: boolean,
    isScreenButtonVisible: boolean,
    isSwitchCameraVisible: boolean,
    isPicturePreviewVisible: boolean,
    isImageEditorVisible: boolean,
    imageToEdit: string,
    imageEdited: string,
    imageUploaded: string,
}

// -- Page HTML elements
let video: HTMLVideoElement | null;
let canvas: HTMLCanvasElement | null;
let screenshotImage: HTMLImageElement | null;
let cameraOptions: HTMLSelectElement | null;
let switchCamera: HTMLButtonElement | null;

// -- Variables
let streamStarted = false;
const memberID = window.storageGetItemValue("Auth-memberID");

let constraints = {
    video: {
      width: { ideal: 1920 },
      height: { ideal: 1080 },
      facingMode: "user"
    }
};

// Initial state
const initState = {
    activeTab: 1,
    isPlayButtonVisible: true,
    isPauseButtonVisible: false,
    isScreenButtonVisible: false,
    isSwitchCameraVisible: false,
    isPicturePreviewVisible: false,
    isImageEditorVisible: false,
    imageToEdit: '',
    imageEdited: '',
    imageUploaded: ''
}


class TakePicture extends React.Component<IProps,IState> {

    state: IState = initState;

    componentDidMount(){
        video = document.querySelector('video');
        canvas = document.querySelector('canvas');
        screenshotImage = document.querySelector('#receivedImage');
        cameraOptions = document.querySelector('.video-options>select');
        switchCamera = document.querySelector('#switchCamera');
        cameraOptions && this.getCameraSelection(); 
    }

    componentDidUpdate(prevProps: IProps, prevState: IState){
        if(!prevState.isImageEditorVisible && this.state.isImageEditorVisible){
            setTimeout(this.editImageEditorStyle, 200);
        }
    }


    // -- CAMERA SETTINGS AND ACTIONS

    getCameraSelection = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
      
        if(videoDevices.length > 1){
            this.setState({ isSwitchCameraVisible: true });
        }
        const options = videoDevices.map(videoDevice => {
          return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
        });
        if(cameraOptions){
            cameraOptions.innerHTML = options.join('');
        }
    };

    startStream = async (constraints: any) => {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.handleStream(stream);
    };

    pauseStream = (videoElem: HTMLVideoElement) => {
        videoElem.pause();
        this.setState({
            isPlayButtonVisible: true,
            isPauseButtonVisible: false,
        })
    };

    stopStream = (videoElem: HTMLVideoElement) => {
        const stream: MediaStream = videoElem.srcObject as MediaStream;
        if(stream !== null) {
            let tracks = stream.getTracks();
            tracks.forEach(function(track: any) {
                track.stop();
            });
        }
        videoElem.srcObject = null;
    }

    handleStream = (stream: MediaStream) => {
        if(video) video.srcObject = stream;
        this.setState({
            isPlayButtonVisible: false,
            isPauseButtonVisible: true,
            isScreenButtonVisible: true
        })
        if(this.props.showPortrait){
            setTimeout(this.setCenteredVideo, 200);
        }
    };

    // Center video inside the container after loading
    setCenteredVideo = () => {
        const videoPlayer = document.getElementById("webcamInput") as HTMLVideoElement;
        const videoContainer = document.getElementById("webcamInputContainer") as HTMLElement;
        const videoPlayerWidth = videoPlayer.offsetWidth;
        const videoContainerWidth = videoContainer.offsetWidth;
        const margin = (videoPlayerWidth - videoContainerWidth) / 2;
        videoPlayer.style.left = -margin+"px";
    }

    doScreenshot = (videoElem: HTMLVideoElement) => {
        var self = this;

        // save screenshot
        if(canvas && screenshotImage) {
            canvas.width = videoElem.videoWidth;
            canvas.height = videoElem.videoHeight;
            //@ts-ignore
            canvas.getContext('2d').drawImage(videoElem, 0, 0, videoElem.videoWidth, videoElem.videoHeight);
            screenshotImage.src = canvas.toDataURL('image/jpeg');
            // crop image after loading it
            screenshotImage.onload = function() {
                if(screenshotImage){
                    const imgCropHeight = 1080;
                    const imgCropWidth = self.props.showPortrait ? 1080 : 1920;
                    const base64Image = self.resizeCrop(screenshotImage, imgCropWidth, imgCropHeight).toDataURL('image/jpeg');
                    self.setState({
                        imageToEdit: base64Image,
                        isPicturePreviewVisible: true,
                    })
                }
            };
        }
      
    };



    // --- EDIT IMAGE 
    // https://github.com/scaleflex/filerobot-image-editor

    editImage = () => {
        this.setState({
            isPicturePreviewVisible: false,
            isImageEditorVisible: true
        })
    }

    editImageEditorStyle = () => {
        const divTitle = document.querySelector(".sc-cvbbAY.jgvbXR") as HTMLElement;
        divTitle.innerHTML = 'Image Editor';

        // Override default click event to show preview image
        const confirmButton = document.querySelector(".sc-iAyFgw.gviMme") as HTMLButtonElement;
        const newConfirmButton = confirmButton.cloneNode(true) as HTMLButtonElement;
        confirmButton.parentElement && confirmButton.parentElement.replaceChild(newConfirmButton, confirmButton);
        newConfirmButton.onclick = (e) => {
            e.preventDefault();
            const canvasEditedPicture = document.getElementById("null_filerobot-image-edit-box") as HTMLCanvasElement;
            video && this.stopStream(video);
            this.setState({
                isImageEditorVisible: false,
                imageEdited: canvasEditedPicture.toDataURL('image/jpeg')
            })
        }
      
        // Change css for the adjust panel when opened
        // Using timeout to wait the end of loading
        const divAdjust = document.querySelector("[name='adjust']") as HTMLElement;
        const divRotate = document.querySelector("[name='rotate']") as HTMLElement;
        divAdjust.onclick = () => {
          setTimeout(this.setAdjustCss, 200);
        }
        divRotate.onclick = () => {
          setTimeout(this.setAdjustCss, 200);
        }  
    }

    setAdjustCss = () => {
        // Change html inside button Apply & Cancel
        const applyButton = document.querySelector(".sc-iAyFgw.jCLhqB") as HTMLButtonElement;
        const cancelButton = document.querySelector(".sc-iAyFgw.sc-cMljjf.iYbGl") as HTMLButtonElement;

        if(applyButton) {
            applyButton.innerHTML = "&#x2714;";
        }

        if(cancelButton){
            cancelButton.innerHTML = "&#x2717;";
            // When cancel current editing, create again events on buttons
            cancelButton.onclick = () => {
              setTimeout(this.editImageEditorStyle, 200)
            }
        }
      
    }

    resizeCrop = (imageObj: HTMLImageElement, width: number, height: number) => {

        // NB: Using natural to get real size and not the adapted one inside modal
        // crop it top center, keeping proportions ( es. width: 1440, height: 1920 )
        var canvasHeight = imageObj.naturalHeight;
        var canvasWidth = imageObj.naturalHeight * (width/height);
        var margin = (imageObj.naturalWidth - canvasWidth) / 2;
      
        // create empty canvas
        var canvas = document.createElement("canvas");               
        canvas.width  = canvasWidth;
        canvas.height = canvasHeight;
        //@ts-ignore
        canvas.getContext("2d").drawImage(imageObj, margin , 0, canvasWidth, canvasHeight, 0, 0, canvasWidth, canvasHeight);
        return canvas //.toDataURL('image/jpeg');
    }



    // -- EVENTS

    handlePlayClick = () => {
        if (streamStarted) {
            video && video.play();
            this.setState({
                isPlayButtonVisible: false,
                isPauseButtonVisible: true
            })
            return;
        }
        if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            this.startStream(constraints);
        }
    }

    handlePauseClick = () => {
        video && this.pauseStream(video);
    }

    handleScreenShotClick = () => {
        video && this.doScreenshot(video);
    }

    handleSwitchCamera = () => {
        const newFacingMode = constraints.video.facingMode == "user" ? "environment" : "user"
        constraints = {
          video: {
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            facingMode: newFacingMode
          }
        };
        if(video){
            this.pauseStream(video);
            this.stopStream(video);
        }
        this.startStream(constraints);
    }


    // -- MANAGE FILE OBJ AND BASE64 IMAGES

    previewUploadedFile = () => {
        const inputFile = document.getElementById("uploadPicture") as HTMLInputElement;
        if(inputFile && inputFile.files && inputFile.files.length > 0) {
            var files = inputFile.files;
            const myFile = files[0];
            var reader = new FileReader();
            var self = this;

            reader.addEventListener("load", function () {
                var image = document.getElementById("uploadedImage") as HTMLImageElement;
                image.title = myFile.name;
                self.setState({ imageUploaded: this.result as string });
            }, false);
      
            reader.readAsDataURL(myFile);

        } else {
            this.setState({ imageUploaded: '' });
        }
    }


    // -- RETURN IMAGE VALUE

    getFilePictureLoaded = () => {
        const uploaded = this.state.activeTab === 2 ? true : false;
        const inputFile = document.getElementById("uploadPicture") as HTMLInputElement;
        if(uploaded && inputFile.files && inputFile.files.length > 0) {
            var files = inputFile.files;
            const myFile = files[0];
            this.props.getImageFile(myFile);
        } else if(!uploaded && this.state.imageEdited.length > 0) {
            const newFile: File = dataURLtoFile(this.state.imageEdited, 'picture_'+memberID+'.jpg');
            this.props.getImageFile(newFile);
        } else {
            this.props.getImageFile(null);
        }
    }


    render(){

        const { 
            activeTab, isPlayButtonVisible, isPauseButtonVisible, isScreenButtonVisible, isSwitchCameraVisible,
            isPicturePreviewVisible, isImageEditorVisible, imageToEdit, imageEdited, imageUploaded 
        } = this.state;

        const { showPortrait } = this.props;

        let takePictureClasses = "take-picture row justify-content-center";
        if(showPortrait){
            takePictureClasses += " portrait";
        }
    
        return (
            <div className="container take-picture-wrapper">
                <div className={takePictureClasses}>
                    <div className={(showPortrait ? "col-lg-10 col-md-12" : "col-12") +" text-center"}>
                        <p>Take or upload your picture</p>
                        <ul className="nav nav-tabs nav-picture" id="myTab" role="tablist">
                            <li className="nav-item" id="navWebcam"
                                onClick={ (e) => { e.preventDefault(); this.setState({ activeTab: 1 }) } }>
                                <a className={`nav-link ${activeTab === 1 ? "active" : ""}`}
                                    id="take-a-picture-tab" 
                                    data-toggle="tab" 
                                    href="#take-a-picture" 
                                    role="tab" 
                                    aria-controls="take-a-picture" 
                                    aria-selected="true">Take a picture</a>
                            </li>
                            <li className="nav-item" id="navUpload"
                                onClick={ (e) => { e.preventDefault(); this.setState({ activeTab: 2 }) } }>
                                <a className={`nav-link ${activeTab === 2 ? "active" : ""}`} 
                                    id="upload-your-picture-tab" 
                                    data-toggle="tab" 
                                    href="#upload-your-picture" 
                                    role="tab" 
                                    aria-controls="upload-your-picture" 
                                    aria-selected="false">Upload your picture</a>
                            </li>
                        </ul>
    
                        <div className="tab-content tab-content-picture">
    
                            {/* TAB take a picture */}
                            <div className={`tab-pane fade ${activeTab === 1 ? "show active" : ""}`} id="take-a-picture" 
                                role="tabpanel" aria-labelledby="take-a-picture-tab">
                                <div className="display-cover" id="step1">

                                    {
                                        !imageEdited &&
                                        <>
                                        <div id="webcamInputContainer" className="video-container">
                                            <video className="video-player" id="webcamInput" autoPlay playsInline></video>
                                            {
                                                showPortrait &&
                                                <img src="assets/images/take-picture/shape-photo.png" style={{ width: '100%', opacity: '0.3', marginTop: '-3rem' }} alt="shape profile" />
                                            }
                                        </div>
                                    
                                        <div className="video-options">
                                            {
                                                isSwitchCameraVisible &&
                                                <button className="btn icon switch-camera" id="switchCamera"
                                                    onClick={this.handleSwitchCamera}>
                                                    <img src="assets/images/take-picture/switch-camera.png" width="25" height="25" alt="switch camera" />
                                                </button>
                                            }
                                            <select name="" id="" className="custom-select d-none">
                                                <option value="">Select camera</option>
                                            </select>
                                        </div>

                                        <div className="controls">
                                            {
                                                isPlayButtonVisible &&
                                                <button className="btn btn-danger icon play" title="Play" id="startWebcam"
                                                    onClick={this.handlePlayClick}>
                                                    <FontAwesomeIcon icon={faPlayCircle} />
                                                </button>
                                            }
                                            {
                                                isPauseButtonVisible &&
                                                <button className="btn btn-info icon pause" title="Pause"
                                                    onClick={this.handlePauseClick}>
                                                    <FontAwesomeIcon icon={faPause} />
                                                </button>
                                            }
                                            {
                                                isScreenButtonVisible &&
                                                <button className="btn btn-outline-success icon screenshot" title="ScreenShot" data-toggle="modal" data-target="#modal-screenshot"
                                                    onClick={this.handleScreenShotClick}>
                                                    <FontAwesomeIcon icon={faImage} />
                                                </button>
                                            }
                                        </div>
                                        </>
                                    }

                                    <canvas className="d-none" id="snapshot-canvas"></canvas>

                                    {/* image edited */}
                                    <img src={imageEdited} id="editedImage" className="img-fluid" />
                            
                                    {/* image preview modal */}
                                    <Modal className='modal-message take-picture-preview' size="lg" show={isPicturePreviewVisible} onHide={() => this.setState({ isPicturePreviewVisible: false })}>
                                        <Modal.Header>
                                            <Button withClass={["red"]} clicked={() => this.setState({ isPicturePreviewVisible: false })}>Delete</Button>
                                            <Button withClass={["confirm"]} clicked={this.editImage}>Proceed</Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <img className="screenshot-image img-fluid" alt="screenshot" src={imageToEdit} /> 
                                        </Modal.Body>
                                    </Modal>
    
                                    {/* image editor */}
                                    <FilerobotImageEditor
                                        show={isImageEditorVisible}
                                        src={imageToEdit}
                                        onClose={() => this.setState({ isImageEditorVisible: false })}
                                        config={{
                                            tools: ['adjust', 'rotate'],
                                            reduceBeforeEdit: {
                                                mode: 'auto',
                                                widthLimit: 2000,
                                                heightLimit: 2000
                                            },
                                            colorScheme: 'light',
                                            translations: {
                                                en: {
                                                    'toolbar.download': 'OK'
                                                }
                                            },
                                        }}
                                    />
    
                                </div>

                                {
                                    imageEdited &&
                                    <>
                                        <div className="form-group col-12 mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button withClass={["primary"]} 
                                                clicked={ () => this.setState(initState, this.getCameraSelection) }>
                                                Take another picture
                                            </Button>
                                            <Button withClass={["confirm"]} 
                                                clicked={this.getFilePictureLoaded}>
                                                Confirm
                                            </Button>
                                        </div>
                                    </>
                                }

                            </div>
    
                            {/* TAB upload picture */}
                            <div className={`tab-pane fade ${activeTab === 2 ? "show active" : ""}`} id="upload-your-picture" 
                                role="tabpanel" aria-labelledby="upload-your-picture-tab">
                                {
                                    isIE() &&
                                    <p id="IE11Message" className="text-left u-font-size-9">
                                        With this browser <span className="text-red">it's not possible</span> to acquire your picture through camera/webcam.<br/>
                                        Please consider to <span className="text-red">use another browser</span> or <span className="text-red">your mobile phone</span>, otherwise you can still upload your picture.
                                    </p>
                                }
                                <div className="input-group mb-3 text-left">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="uploadPicture" accept=".jpg,.jpeg,.png,.tiff,.bmp" 
                                            onChange={this.previewUploadedFile} />
                                        <label className="custom-file-label" htmlFor="uploadPicture">Choose file...</label>
                                    </div>
                                </div>
                                {/* image uploaded */}
                                <img src={imageUploaded} id="uploadedImage" className="img-fluid" />
                                {
                                    imageUploaded.length > 0 && 
                                    <div className="form-group mt-4">
                                        <Button withClass={["confirm","right"]} 
                                            clicked={this.getFilePictureLoaded}>
                                            Confirm
                                        </Button>
                                    </div>
                                }
                            </div>



                            {/* Used to convert image from webcam */}
                            <img src="" id="receivedImage" />
                            
    
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default TakePicture;
import React from 'react';

const Interprefy = () => {

    return (
        <iframe src="https://interpret.world/loginlink?token=ZBTH_0209" frameBorder={0}></iframe>
    )

}

export default Interprefy;
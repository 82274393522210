import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import axiosModule from 'axios';
import history from '../../config/history';
import { validatePassword, hideLoader } from '../../config/functions';

import Button from '../../Components/_UI/Button/Button';
import Header from '../../Components/LoginPage/LoginPageHeader';
import EventHeader from '../../Components/EventHeader/EventHeader';
import Countdown from '../../Components/Countdown/Countdown';

let _token = '';

const ChangePasswordPage = () => {

    const [statusError, setStatusError] = useState<number>(0);
    const [isCodeValid, setCodeValid] = useState<boolean>(false);

    useEffect(() => {

        const search = window.location.search.substring(1);
        const searchObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        const urlData = {
            email: searchObj.email,
            code: searchObj.code 
        }
        axios.post('Members/CheckPasswordChange', urlData).then(res => {
            if(res.data.status === "success"){
                _token = res.data.data.token;
                // window.storageSetItem("Auth-token", res.data.data.token);
                setCodeValid(true);
                hideLoader();
            } else {
                hideLoader();
            }
        }).catch(err => {
            setStatusError(err.response ? err.response.status : 404);
            hideLoader();
        });

    }, [])

    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const handleSubmit = (values: { password: string, password2: string }) => {
        setStatusError(0);
        if(values.password !== values.password2){
            setStatusError(418);
            return;
        }
        const data = { 
            newPassword: values.password, 
            newPasswordConfirm: values.password2 
        }
        const config = { headers: { Authorization: _token } }
        axiosModule.post(window.apiURL+'Members/ChangePassword', data, config).then(res => {
            if(res.data.status === "success"){
                setStatusError(200);
                window.removeStoredUserData();
                setTimeout(() => history.push('/change-password-success'), 500);
            }
        }).catch(err => {
            setStatusError(err.response ? err.response.status : 404);
        });
    }

    const formValidatePassword = (password: string) => {
        let error;
        if (!validatePassword(password))
            error = 'Password length must be at least 8 characters';
        return error;
    }

    return (
        <div className="forgot-password">
            <div className="interactive-page-login container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="interactive-page-login-header">
                            <EventHeader />
                        </div>
                        <div className="interactive-page-login-countdown">
                            <Countdown />
                        </div>
                        {
                            isCodeValid ?
                            <main className="interactive-page-form-wrapper">
                                <Formik
                                    initialValues={{
                                        password: '',
                                        password2: '',
                                    }}
                                    onSubmit={values => {
                                        handleSubmit(values);
                                    }}
                                >
                                    {({ errors, touched, values }) => (
                                        <Form className="interactive-page-form">
                                            <div className="interactive-page-form-content">
                                                <h3 className="interactive-page-form__title">Change your password</h3>
                                                <h4 className="interactive-page-form__subtitle">Please insert a new password.</h4>
                                                <hr className="interactive-page-form__separator" />
                                                {/* password */}
                                                <div className="interactive-page-form__group" >
                                                    <label className="interactive-page-form__label" htmlFor="password">PASSWORD</label>
                                                    <Field 
                                                        name="password" 
                                                        type="password"
                                                        className="form-control interactive-page-form__field" 
                                                        placeholder="Min. 8 characters"
                                                        validate={() => formValidatePassword(values.password)} 
                                                    />
                                                    <div className={`invalid-feedback u-font-size-10 ${errors.password && touched.password ? "d-block" : ""}`}>
                                                        {errors.password}
                                                    </div>
                                                </div>
                                                {/* retype password */}
                                                <div className="interactive-page-form__group" >
                                                    <label className="interactive-page-form__label" htmlFor="password2">RETYPE PASSWORD</label>
                                                    <Field 
                                                        name="password2" 
                                                        type="password"
                                                        className="form-control interactive-page-form__field" 
                                                        placeholder="Min. 8 characters"
                                                        validate={() => formValidatePassword(values.password2)} 
                                                    />
                                                    <div className={`invalid-feedback u-font-size-10 ${errors.password2 && touched.password2 ? "d-block" : ""}`}>
                                                        {errors.password2}
                                                    </div>
                                                </div>
                                                {/* submit button */}
                                                <Button type="submit"
                                                    withClass={["primary","center"]}
                                                    classes="interactive-page-form__submit u-font-size-12 font-weight-bold mt-md-5 mt-4"
                                                >CHANGE PASSWORD</Button>
                                                { statusError === 200 && <div className="valid-feedback u-font-size-10 d-block">Your password has been changed successfully!</div> }
                                                { statusError === 401 && <div className="invalid-feedback u-font-size-10 d-block">Unauthorized</div> }
                                                { statusError === 403 && <div className="invalid-feedback u-font-size-10 d-block">Forbidden</div> }
                                                { statusError === 404 && <div className="invalid-feedback u-font-size-10 d-block">Network error. Please check your internet connection and try again.</div> }
                                                { statusError === 418 && <div className="invalid-feedback u-font-size-10 d-block">The password and retype password fields doesn't match</div> }
                                                { ![0,200,401,403,404,418].includes(statusError) && 
                                                    <div className="invalid-feedback u-font-size-10 d-block">An error has occurred. Please try again later.</div> }
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </main> :
                            <main className="interactive-page-login-error">
                                <h1 className="u-font-size-20 text-center">Email or confirmation code not valid</h1>
                                <h2 className="u-font-size-12 text-center">Please follow the <Link to="/forgot-password">forgot password</Link> procedure</h2>
                            </main>
                        }
                        {/* back button */}
                        <Button type="submit"
                            withClass={["secondary","center"]}
                            classes="interactive-page-back u-font-size-12 font-weight-bold mt-md-5 mt-4"
                            clicked={() => history.push('/')}
                        >&lt; BACK</Button>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default ChangePasswordPage;
import  React from  "react";
import { Route, Redirect } from  "react-router-dom";
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import PageForbidden from './ErrorHandler/PageForbidden';

// #IMPORTANT: Use external Component for private routing to avoid multiple DidMount call when loading a component on a private route
const PrivateRoute: React.FC<{ 
  Component: React.ComponentType<any>, 
  Props?: any, 
  path: string, 
  location?: any, 
  exact?: boolean 
}> = ({ Component, Props, ...rest }) => {
        return <Route {...rest} render={(routeProps) => {
            if(Props.authToken !== null){
              const roles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue('Auth-roles');
              const isChatAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "ChatAdmin") >= 0 : false;
              const isEventAdmin = roles ? roles.findIndex(obj => obj.roleDescription === "EventAdmin") >= 0 : false;
              if(routeProps.match.url.indexOf('chat-admin') >= 0){
                if(isChatAdmin){
                  return <Component {...routeProps} {...Props} />
                } else {
                  return <PageForbidden />
                }
              }
              if(routeProps.match.url.indexOf('events-admin') >= 0){
                if(isEventAdmin){
                  return <Component {...routeProps} {...Props} />
                } else {
                  return <PageForbidden />
                }
              }
              return <Component {...routeProps} {...Props} />
            }
            else {
              // Save the current path before to redirect
              window.storageSetItem("redirectTo", window.location.pathname+window.location.search, true);
              return (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: routeProps.location }
                }} />
              )
            }
          }} />
      };

export default PrivateRoute;
import React from 'react';
import { match } from "react-router-dom";
import Carousel, { Modal, ModalGateway } from 'react-images';
import FlipMove from 'react-flip-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

import { wsSetup, wsSend, messageSubmit, liveAnswerSubmit } from '../../config/functions';
import axios from '../../config/axios';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';

import loadPage from '../../Components/_HOC/loadPage';
import Button from '../../Components/_UI/Button/Button';
import { ChatTypeRes } from '../../Models/ResponseModels/Chat';

import ChatDetails from '../../Components/Chat/ChatDetails';
import ChatMessage from '../../Components/Chat/ChatMessage';
import ChatTextarea from '../../Components/Chat/ChatTextarea';

import { WebSocketTypeRes } from '../../Models/ResponseModels/WebSocketModels';
import { ChatTypeReq } from '../../Models/RequestModels/Chat';

var CryptoJS = require("crypto-js");

interface DetailParams {
    key: string;
}

interface IChatMessageWithOrder extends ChatTypeRes.IChatMessage {
    order: number
}

interface IQuote {
    messageId: string, 
    quotedUserName: string, 
    quotedUserLastName: string, 
    quoteContent: string
}

interface IProps {
    response: any,
    match?: match<DetailParams>,
    showIsAtEvent?: boolean,
    showDetails?: boolean,
    showMessages?: boolean,
    showTextArea?: boolean,
    sendAttachments?: boolean,
    isBackstageChat?: boolean,
    maxMsgLoaded?: number,
    liveWebinar?: boolean,
    onMessageSubmit: Function
}

interface IState {
    chatRoomID: number,
    chatInfo: ChatTypeRes.IChat,
    messages: IChatMessageWithOrder[],
    messagesOrder: 'TopRated' | 'MostRecent' | 'Default',
    quotedMessage: IQuote | null,
    images: { src: string}[],
    numMsgToShow: number,                           // set number of messages to load into the chat
    isChatClosed: boolean,
    isAtEvent: boolean,
    isModalImagesOpen: boolean,
    isNotAllowed: boolean,
    showMessageSentNotification: boolean,
    showArchivedMessages: boolean,
}

var host="192.168.1.200";
var port = "";
var protocol = "ws";
// var debug = true;

/* PROD: */
// use room 1 to test messages

var debug = false;

// var chatIsClosed = false;	    //closed chat during set times, if true users can't write in chat (but can see previous messages)
var msgBox:any = null 	        //html container of the received messages
var wsUri = null;	            //websocket URI
var connectionDate:any = null;	//timestamp of the last websocket connection
var user:any;
var timerKeepAlive:any;
var hasNewMessages:boolean = true;

const initChatInfo: ChatTypeRes.IChat = {
    chatRoomID: 0,
    chatRoomDescription: '',
    chatRoomTitle: '',
    isOpenToMembers: false,
    attachmentsFolder: 0,
    openingTimes:[],
    partecipants: []
}

class Chat extends React.Component<IProps,IState> {

    websocket:any = null;	//websocket instance

    // #IMPORTANT: setting default props value in class component
    static defaultProps = {
        showDetails: true,
        showMessages: true,
        showTextArea: true,
        sendAttachments: true,
        maxMsgLoaded: 999,
    }

    state: IState = {
        chatRoomID: 0,
        chatInfo: {...initChatInfo},
        messages: [],
        messagesOrder: this.props.isBackstageChat ? 'MostRecent' : 'TopRated',
        quotedMessage: null,
        images: [],
        numMsgToShow: 0,
        isAtEvent: false,
        isModalImagesOpen: false,
        isChatClosed: false,
        isNotAllowed: false,
        showMessageSentNotification: false,
        showArchivedMessages: false
    }

    //New global websocket support
    onMessageSubmit = (message: WebSocketTypeRes.IWsMessage) => {
        console.log("Chat.tsx",message);
        this.props.onMessageSubmit(message);

    }
    //End global websocket support
    componentDidMount(){

        let key: number | string = 0;
        if(this.props.match){
            key = parseInt(this.props.match.params.key);
            if(isNaN(key))
                key = 0;
        }
        // Search for room to open
        // NB: Using state and not local storage to manage multiple chatRoom opened simultaneously
        this.setState({ chatRoomID: key });
        // window.localStorage.setItem("roomID", key.toString());

        var info = this.props.response.data;

        // 1- Check if user can access to the chat
        if(info.status === "success")
        {
            user = info.data;
            if(debug) console.log("Chat.tsx","USER DATA", info.data)
            if(user.authorized && !user.isBanned)
            {
                // 2 - Checking if chat is open:
                axios.get('Chat/' + key + "/IsOpen").then(res => {
                    var info = res.data.data;
                    if(!info) {
                        this.closeChat();
                        var html = "This Case Forum is not scheduled for now. Please come back later.";
                        $("#chat-closed-container .description").html(html);
                        this.setState({ isChatClosed: true });
                    }
                    else 
                    {
                        this.afterComponentLoad();
                        // When opening faculty chat view hide header and footer
                        if(this.props.liveWebinar && this.props.match && this.props.match.path.includes('faculty-chat')){
                            $('#prompt').addClass("d-none");
                            $("#main-nav").addClass("d-none");
                            $(".footer").addClass("d-none");
                            $(".chat-outer").css("max-height","98vh")
                            $(".chat-height-100").css({ marginTop: 0, marginBottom: 0 })
                        }
                        // this.isAtEvent = user.isAtEvent;
                    }            
                    console.log("Chat - childComponentsLoaded");
                    window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
                }).catch(e => {
                    console.log("Chat.tsx","Chat/IsOpen. API connection failed");
                    window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
                });                
            }
            else
            {
                this.closeChat();
                this.setState({ isNotAllowed: true });
                console.log("Chat - childComponentsLoaded");
                window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
            }

        }
        else
        {
            //Mex UTENTE NON CONNESSO (TODO)
            console.log("Chat - childComponentsLoaded");
            window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
        }

        // event scroll
        $(document).ready(function(){
            $("#message-box.message-box-chat-"+key).scroll(function(){
                const scrollTop = $("#message-box.message-box-chat-"+key).scrollTop();
                if(scrollTop){
                    if(scrollTop > 100){
                        $("#chat-main-container-"+key+" .scroll-top").fadeIn();
                    } else {
                        $("#chat-main-container-"+key+" .scroll-top").fadeOut();
                    }
                }
            });
        });
    }

    componentWillUnmount() {

        this.closeChat();
        clearInterval(timerKeepAlive);

        if(window.webSocket!== null)
        {
            window.webSocket.close();
            window.webSocket = null;
            setTimeout(function(){
                console.log("Chat.tsx","clearing timeout");
                clearTimeout(window.wsReconnectTo);
            },500); 
        }
        
        // When exiting show again header and footer page
        if(this.props.liveWebinar && this.props.match && this.props.match.path.includes('faculty-chat')){
            $('#prompt').removeClass("d-none");
            $("#main-nav").removeClass("d-none");
            $(".footer").removeClass("d-none");
        }
        
    }


    componentDidUpdate(prevProps: IProps, prevState: IState){
        // Re-ordering messages
        if(prevState.messagesOrder !== this.state.messagesOrder){
            const orderedMessages = [...this.state.messages].sort(this.compare());
            this.setState({ messages: orderedMessages });
        }
    }


    LoadChatInformations = () => {
        axios.get("Chat/" + this.state.chatRoomID).then(res => {
            var info = res.data.data;
            this.setState({ chatInfo: info });
            // this.attachmentsFolder = info.attachmentsFolder;
        }).catch(e => {
            console.log("Chat.tsx","Error in retrieving chat info from database: ", e);
        })

    }


    loadMessages = () => {
        // var connectionTime = connectionDate ? connectionDate : new Date();
        // connectionTime = connectionTime.getTime()/1000;
        var self = this;
        const data: ChatTypeReq.IAllMessages = {
            pagination: {
                offset: 0,
                limit: 999
            },
            showArchived: this.state.showArchivedMessages
        }

        // -- set messages order
        const messagesOrder = this.state.messagesOrder;
        if(messagesOrder === 'TopRated'){
            data.sorting = [{
                fieldName: 'Vote',
                desc: true
            }]
        } else {
            data.sorting = [{
                fieldName: 'Timestamp',
                desc: true
            }]
        }

        axios.post("Chat/" + this.state.chatRoomID + "/AllMessages", data).then(res => {
            const messages: ChatTypeRes.IChatMessage[] = res.data.data;
            // If message saved as favorite, show first
            // const orderedMessages = messages.map((message) => ({ ...message, order: message.faculty_Special ? 1 : 999 })).sort(this.compare());
            // Get messages saved as favorite from localStorage
            const favoriteMessageIDs: number[] = window.storageGetItemValue('favoriteMessageIDs') || []
            const orderedMessages = messages.map((message) => ({ ...message, order: favoriteMessageIDs.includes(message.id) ? 1 : 999 })).sort(this.compare());
            this.setState({ messages: orderedMessages, numMsgToShow: messages.length });

            // Check if new messages have been posted and scroll down
            // hasNewMessages = this.checkNewMessages(messages, window.storageGetItemValue('oldMessagesIDs') || []);
            // setTimeout(function(){
            //     if(hasNewMessages){
            //         self.scrollTolastMessage();
            //     }
            //     window.storageSetItem('oldMessagesIDs', JSON.stringify(messages.map(msg => msg.id)));
            // }, 300);
            // if(!hasNewMessages){
            //     $(".no-new-messages").show();
            // }
            // setTimeout(function(){
            //     if(!hasNewMessages){
            //         $(".no-new-messages").fadeOut(200);
            //     }
            // }, 1000);
        }).catch(e => {
            console.log("Chat.tsx","Error in retrieving messages from database: ", e);
        })
    }

    checkNewMessages = (newMessages: ChatTypeRes.IChatMessage[], oldMessagesIDs: number[]) => {
        let newMessagesMaxID = 0;
        newMessages.forEach(msg => {
            if(msg.id > newMessagesMaxID){
                newMessagesMaxID = msg.id
            }
        });
        let oldMessagesMaxID = 0;
        if(oldMessagesIDs.length > 0){
            oldMessagesMaxID = Math.max(...oldMessagesIDs)
        }
        if(newMessagesMaxID > oldMessagesMaxID){
            return true;
        } else {
            return false;
        }
    }

    scrollTolastMessage = () => {
        var scrollTo = $("#message-box.message-box-chat-"+this.state.chatRoomID).prop("scrollHeight") + 300;
        $("#message-box.message-box-chat-"+this.state.chatRoomID).animate({ scrollTop: scrollTo}, 1000);
    }

    scrollToFirstMessage = () => {
        $("#message-box.message-box-chat-"+this.state.chatRoomID).animate({ scrollTop: 0}, 500);
    }

    // Function executed when the toggle "archived messages" inside detail box is enabled / disabled
    handleArchivedMessages = (showArchivedMessages: boolean) => {
        this.setState({ showArchivedMessages }, this.loadMessages)
    }

    afterComponentLoad = () => {

        var self = this;
        if(this.props.showDetails){
            this.LoadChatInformations();
        }
        if(this.props.showMessages){
            this.loadMessages();
        }
        // var isDisabledUser = !window.userInRole("ChatPartecipant") && !window.userInRole("ChatAdmin") && !window.userInRole("ChatEditor");
        var isDisabledUser = window.storageGetItemValue("Auth-name") === "Backstage";

        if((window.webSocket == null || window.webSocket.readyState === 3) && !isDisabledUser){
            let userName = window.storageGetItemValue('Auth-name') + ' ' + window.storageGetItemValue('Auth-lastName');
            userName += this.props.showTextArea ? ' (MP)' : ' (CA)';
            if(debug) console.log("Chat.tsx","WSSetup called by Chat.tsx");
            wsSetup(userName, this.state.chatRoomID.toString());
        }

        // EVENT: Message received from server
        window.addEventListener('loadChatMessage', (e: any) => {

            const message = e.detail.message;
            if(debug) console.log("Chat.tsx","Message from websocket:", message);

            var response: IChatMessageWithOrder = {...message};
            if(response.room_id !== this.state.chatRoomID) {
                if(debug) console.log("Chat.tsx","Ignoring message for room " + response.room_id);
            } else {
                if(debug) console.log("Chat.tsx","Received message:", response);
                this.setState({ showMessageSentNotification: false });

                switch(response.type)
                {
                    case "usermsg":
                    case "system":
                        const messages = [
                            ...this.state.messages, 
                            response
                        ].sort(this.compare());
                        this.setState({ messages: messages });
                        break;
                    case "command":
                        this.executeCommand(response, null);
                        break;
                }

                // Message sent successfully sent: notify the user (1s delay + 3s opacity --> Chat.scss)
                if(window.storageGetItemValue("Auth-memberID") === message.user_id){
                    this.setState({ showMessageSentNotification: true });
                    setTimeout(() => this.setState({ showMessageSentNotification: false }), 4000);
                }
            }
        }, false)


        // EVENT: Message deleted
        window.addEventListener('deleteMessage', (e: any) => {
            if(window.debug) console.log("Chat.tsx",'deleteMessage', e);
            const messageID = e.detail ? parseInt(e.detail.messageID) : 0;

            if(messageID > 0){
                this.removeMessage(messageID);
            }

        }, false)


        // EVENT: Message archived
        window.addEventListener('archiveMessage', (e: any) => {
            if(window.debug) console.log("Chat.tsx",'archiveMessage', e);
            const messageID = e.detail ? parseInt(e.detail.messageID) : 0;

            if(messageID > 0){
                // default view: when message is archived, remove it
                if(!this.state.showArchivedMessages){
                    this.removeMessage(messageID);
                } else {
                    // archived view: when message is archived update status
                    const updatedMessage = this.state.messages.filter(message => message.id === messageID)[0];
                    if(updatedMessage){
                        updatedMessage.archived_at = new Date();
                        const messages = [
                            ...this.state.messages.filter(message => message.id !== messageID), 
                            updatedMessage
                        ].sort(this.compare());
                        this.setState({ messages: messages });
                    }
                }
            }

        }, false)


        // EVENT: Message unarchived
        window.addEventListener('unArchiveMessage', (e: any) => {
            if(window.debug) console.log("Chat.tsx",'unArchiveMessage', e);
            const messageID = e.detail ? parseInt(e.detail.messageID) : 0;

            if(messageID > 0){
                // default view: when message is restored, show it
                if(!this.state.showArchivedMessages){
                    axios.get('Chat/'+window.localStorage.getItem("roomID")+'/Message/'+messageID)
                        .then(res => {
                            const response = res.data;
                            if(response.status === 'success'){
                                const newMessage: IChatMessageWithOrder = { ...response.data, order: response.data.faculty_Special ? 1 : 999 }
                                const messages = [
                                    ...this.state.messages, 
                                    newMessage
                                ].sort(this.compare());
                                this.setState({ messages: messages });
                            }
                        })
                } else {
                    // archived view: when message is restored update status
                    const updatedMessage = this.state.messages.filter(message => message.id === messageID)[0];
                    if(updatedMessage){
                        updatedMessage.archived_at = null;
                        const messages = [
                            ...this.state.messages.filter(message => message.id !== messageID), 
                            updatedMessage
                        ].sort(this.compare());
                        this.setState({ messages: messages });
                    }
                }
            }

        }, false)


        // EVENT: Message voted
        window.addEventListener('voteMessage', (e: any) => {
            if(window.debug) console.log("Chat.tsx",'voteMessage', e);
            const messageID = e.detail ? parseInt(e.detail.messageID) : 0;
            if(messageID > 0){
                this.updateMessageVote(messageID, 1);
            }
        }, false)

        // EVENT: Message unvoted
        window.addEventListener('unVoteMessage', (e: any) => {
            if(window.debug) console.log("Chat.tsx",'unVoteMessage', e);
            const messageID = e.detail ? parseInt(e.detail.messageID) : 0;
            if(messageID > 0){
                this.updateMessageVote(messageID, -1);
            }
        }, false)

    
        //Close websocket and send log on page close.
        $(window).on('beforeunload', function() {
            window.alert("befUnload");
            //self.websocket.close();
        });
        
        //Load previous chat messages TODO
        $(".chat-wrapper").on("click", "#loadPreviousMessages", function(){
            this.loadMessages();
            $("#previous-message-box").fadeIn(1000);
        });
        
    }


    removeMessage = (msgId: number) => {
        const oldMessagesIDs: number[] | null = window.storageGetItemValue('oldMessagesIDs');
        const favoriteMessageIDs: number[] | null = window.storageGetItemValue('favoriteMessageIDs');
        if(oldMessagesIDs !== null){
            const _oldMessagesIDs = oldMessagesIDs.filter(msgID => msgID !== msgId);
            window.storageSetItem('oldMessagesIDs', JSON.stringify(_oldMessagesIDs));
        }
        // Remove messages saved as favorite from localStorage
        if(favoriteMessageIDs !== null){
            const _favoriteMessageIDs = favoriteMessageIDs.filter(msgID => msgID !== msgId);
            window.storageSetItem('favoriteMessageIDs', JSON.stringify(_favoriteMessageIDs));
        }

        this.setState((prevState) => {
            return {
                messages: prevState.messages.filter(message => {
                    return message.id !== msgId
                })
            }
        })
    }

    updateMessageVote = (msgId: number, votesToAdd: number) => {
        const updatedMessage = this.state.messages.filter(message => message.id === msgId)[0];
        if(updatedMessage){
            updatedMessage.upVotes = updatedMessage.upVotes + votesToAdd;
            const messages = [
                ...this.state.messages.filter(message => message.id !== msgId), 
                updatedMessage
            ].sort(this.compare());
            this.setState({ messages: messages });
        }
    }

    
    executeCommand = (msg: any, target: any) => {
        target = target || msgBox;
        switch(msg.command)
        {
            case "DEL":
                var id = msg.id;
                const delMessages = this.state.messages.filter(message => message.id !== id);
                this.setState(prevState => ({ messages: delMessages, numMsgToShow: prevState.numMsgToShow - 1 }));
                break;
            case "DEL_USER_MSG":
                var userid = msg.id;
                var numDeletedMsg = 0;
                const delUserMessages = this.state.messages.filter(message => {
                    if(message.user_id !== userid){
                        return true;
                    } else {
                        numDeletedMsg ++;
                        return false;
                    }
                });
                this.setState(prevState => ({ messages: delUserMessages, numMsgToShow: prevState.numMsgToShow - numDeletedMsg }));
                break;
            case "BAN_USER":
                var idToBan = msg.id;
                if(user.id == idToBan)
                {
                    window.location.href = "/";
                }
                break;
        };
    }

    // Update property voted on message before loading likes number
    setLikedMessage = (msgId: number, isMessageLiked: boolean) => {
        const updatedMessage = this.state.messages.filter(message => message.id === msgId)[0];
        updatedMessage.voted = isMessageLiked;
        if(updatedMessage){
            const messages = [
                ...this.state.messages.filter(message => message.id !== msgId), 
                updatedMessage
            ].sort(this.compare());
            this.setState({ messages: messages });
        }
    }

    // Quote a message and place it in the textarea
    setQuotedMessage = (msgToQuote: IQuote) => {
        this.setState({ quotedMessage: msgToQuote });
    }

    setImagesModal = (images: { src: string}[]) => {
        this.setState({
            images: images,
            isModalImagesOpen: true
        })
    }

    // When message is highlighted, set on top
    setHighlightMessage = (msg: { messageId: number, order: number }) => {
        const messages = this.state.messages.filter(message => message.id !== msg.messageId);
        const msgEdited = this.state.messages.filter(message => message.id === msg.messageId)[0];
        if(msgEdited){
            msgEdited.order = msg.order;
            let sortedMessages = [...messages, msgEdited].sort(this.compare());
            this.setState({ messages: sortedMessages })
        }
    }

    // Open/close lightbox with image
    toggleImageModal = () => {
        this.setState(prevState => {
            return {
                isModalImagesOpen: !prevState.isModalImagesOpen
            }
        });
    }

    compare() {
        const messagesOrder = this.state.messagesOrder;
        if(messagesOrder === 'TopRated'){
            // -- Top rated first, then by timestamp desc
            return function (a: IChatMessageWithOrder, b: IChatMessageWithOrder) {
                if (a['upVotes'] > b['upVotes']) return -1;
                if (a['upVotes'] === b['upVotes'] && new Date(a['timestamp']) < new Date(b['timestamp'])) return 1;
                if (a['upVotes'] === b['upVotes'] && new Date(a['timestamp']) > new Date(b['timestamp'])) return -1;
                if (a['upVotes'] < b['upVotes']) return 1;
                return 0;
            }
        } else if(messagesOrder === 'MostRecent') {
            // -- Most recent: new messages first
            return function (a: IChatMessageWithOrder, b: IChatMessageWithOrder) {
                if (new Date(a['timestamp']) > new Date(b['timestamp'])) return -1;
                if (new Date(a['timestamp']) < new Date(b['timestamp'])) return 1;
                return 0;
            }
        } else {
            // -- Default: favorites first, then by timestamp asc
            return function (a: IChatMessageWithOrder, b: IChatMessageWithOrder) {
                if (a['order'] > b['order']) return 1;
                if (a['order'] === b['order'] && new Date(a['timestamp']) > new Date(b['timestamp'])) return 1;
                if (a['order'] === b['order'] && new Date(a['timestamp']) < new Date(b['timestamp'])) return -1;
                if (b['order'] > a['order']) return -1;
                return 0;
            }
        }
    }

    openChat = () => {
        this.setState({ isChatClosed: false });
    }
    
    closeChat = () => {
        this.setState({ isChatClosed: true });
    }

    render(){

        const { 
            chatRoomID, 
            messages, 
            messagesOrder, 
            chatInfo, 
            quotedMessage, 
            images, 
            numMsgToShow, 
            showMessageSentNotification 
        } = this.state;

        const { 
            showDetails = true, 
            showMessages = true, 
            showTextArea = true, 
            sendAttachments = true, 
            maxMsgLoaded = 999, 
            liveWebinar = false, 
            isBackstageChat = false 
        } = this.props;

        const ChatTextArea = (
            <ChatTextarea 
                chatRoomID={chatRoomID}
                attachmentsFolder={chatInfo.attachmentsFolder} 
                quotedMessage={quotedMessage} 
                websocket={window.webSocket} 
                liveWebinar={liveWebinar}
                isBackstageChat={isBackstageChat}
                isAttachmentAvailable={sendAttachments}
                setQuotedMessage={this.setQuotedMessage}
                onSubmit = {this.onMessageSubmit}
            />
        )

        return (
            <ContextAppSettings.Consumer>
            {
                settings => (
                    // setting specific id to have multiple chat associated to different rooms in the same page
                    <div id={"chat-main-container-"+chatRoomID} className={"chat-wrapper "+(showMessages ? "chat-height-100 ": "")+(isBackstageChat ? "backstage " : "")}>
                        <div className="preloader"></div>
        
                        {/* // Usare row e col?
                        //Attenzione, in CSS principale .container ha padding-top: 10px; */}
                        {
                            showDetails || showMessages ?
                            <div className="container chat-height-100" style={{ paddingTop: 0 }}> 
                                <div className="row chat-height-100">
                                    {
                                        this.state.isNotAllowed ?
                                        <div className="col-12 hidden clearfix problem-container" id="not-allowed-container">
                                            {/* <img src={img_case_forum} className="img-fluid" alt="" /> */}
                                            <div className="col-12 chat-opening-message error clearfix" id="chat-not-allowed-container">
                                                <span className="title">This is a private case forum session, your user is not allowed to enter.</span>
                                                <span className="description">Sorry for the inconvenience.</span>
                                            </div>
                                        </div> : null
                                    }
                                    {
                                        this.state.isChatClosed ?
                                        <div className="col-12 hidden clearfix problem-container" id="chat-closed-container">
                                            {/* <img src={img_case_forum} alt="case forum closed" className="img-fluid" id="banner-image" /> */}
                                            <div className="chat-closed-content chat-opening-message">
                                                <span className="title">This Case Forum is closed.</span>
                                                <span className="description">&nbsp;</span>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="col-12 p-0 chat-outer">
                                        {/* details */}
                                        {
                                            showDetails ?
                                            <ChatDetails 
                                                chatInfo={this.state.chatInfo} 
                                                liveWebinar={liveWebinar} 
                                                fnShowArchivedMessages={this.handleArchivedMessages}
                                            /> :
                                            null
                                        }
                                        {/* textarea */}
                                        { showTextArea ? 
                                            <div className="user-panel-wrapper">
                                                {ChatTextArea}
                                                <p className={"user-panel-feedback "+(showMessageSentNotification ? "active" : "")}>Message successfully sent!</p>
                                            </div>
                                        : null }
                                        {/* messages */}
                                        {
                                            showMessages ?
                                            <div className="chat-wrapper">
                                                { !hasNewMessages && <p className="no-new-messages u-font-size-9">No new messages</p> }
                                                <div className={"chat-order-controls"+(isBackstageChat ? " invisible" : "")}>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            className="form-check-input" id={"top_rated_chat_"+chatRoomID} 
                                                            type="radio" name={"order_msg_chat_"+chatRoomID} value="top-rated" 
                                                            onChange={() => this.setState({ messagesOrder: 'TopRated' })}
                                                            checked={messagesOrder === 'TopRated'}
                                                        />
                                                        <label className="form-check-label" htmlFor={"top_rated_chat_"+chatRoomID}>
                                                            { translations.chat.check_top_rated[settings.language] }
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            className="form-check-input" id={"most_recent_chat_"+chatRoomID}
                                                            type="radio" name={"order_msg_chat_"+chatRoomID} value="most-recent" 
                                                            onChange={() => this.setState({ messagesOrder: 'MostRecent' })}
                                                            checked={messagesOrder === 'MostRecent'}
                                                        />
                                                        <label className="form-check-label" htmlFor={"most_recent_chat_"+chatRoomID}>
                                                            { translations.chat.check_most_recent[settings.language] }
                                                        </label>
                                                    </div>
                                                </div>
                                                <div id="message-box" className={"message-box-chat-"+chatRoomID}>
                                                    <button id="loadPreviousMessages" className="btn btn-primary btn-sm d-none">Load previous messages</button>
                                                    <div className="clearfix pb-2" id="previous-message-box">
                                                        {/* #IMPORTANT: order elements of a list with animation. Component must be wrapped in a div with unique key */}
                                                        <FlipMove
                                                            staggerDurationBy="20"
                                                            duration={400}
                                                            enterAnimation="accordionVertical"
                                                            leaveAnimation="accordionVertical"
                                                        >
                                                        { 
                                                            messages.slice(0, numMsgToShow + maxMsgLoaded).map((message) => { 
                                                                return <div key={message.id.toString()}>
                                                                    <ChatMessage
                                                                        chatRoomID={chatRoomID}
                                                                        message={message} 
                                                                        user={user} 
                                                                        isChatClosed={this.state.isChatClosed} 
                                                                        showTextArea={showTextArea}
                                                                        liveWebinar={liveWebinar}
                                                                        isBackstageChat={isBackstageChat}
                                                                        websocket={window.webSocket} 
                                                                        setQuotedMessage={this.setQuotedMessage}
                                                                        setImagesModal={this.setImagesModal}
                                                                        setHighlightMessage={this.setHighlightMessage}
                                                                        setLikedMessage={this.setLikedMessage}
                                                                        onSubmit={this.onMessageSubmit}
                                                                    />
                                                                </div>
                                                            }) 
                                                        }
                                                        </FlipMove>
                                                        {
                                                            numMsgToShow + maxMsgLoaded < this.state.messages.length ?
                                                            <Button icon="faRedoAlt" withClass={["primary","small","w-100"]}
                                                                clicked={() => { this.setState(prevState => ({ numMsgToShow: prevState.numMsgToShow + maxMsgLoaded })) }}>
                                                                    Load more messages
                                                            </Button> :
                                                            null
                                                        }
                                                    </div>
                                                    <div className="" id="session-message-box">
        
                                                    </div>
                                                </div>
                                                {/* scroller */}
                                                <div className="scroll-top" style={{ display: 'none' }} onClick={this.scrollToFirstMessage}>
                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                </div>
                                            </div> :           
                                            null
                                        }
                                    </div>
                                </div>
                            </div> :
                            showTextArea ? 
                                <div className="user-panel-wrapper">
                                    {/* <h1 className="u-font-size-14 font-weight-bold mb-1">Live question</h1> */}
                                    {ChatTextArea}
                                    <p className={"user-panel-feedback "+(showMessageSentNotification ? "active" : "")}>Message successfully sent!</p>
                                </div>
                             : null
                        }
                        <ModalGateway>
                            {this.state.isModalImagesOpen && images && images.length > 0 ? (
                                <Modal onClose={this.toggleImageModal}>
                                    <Carousel views={images} />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                        <script type="application/javascript" src="/libs/file.js"></script>
                    </div>
                            
                )
            }
            </ContextAppSettings.Consumer>
        )

    }

}

export default loadPage(
    Chat,
    { url: 'Chat/{KEY}/UserHasAccess', method: 'GET', data: null },
    {},
    '',
    false,
    false
);
import React, { useEffect } from 'react'
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { TMainTemplate } from '../../config/context';

// Page live interactive
import LivePageBeforeEvent from './LivePageBeforeEvent';
import LivePageTemplateOne from './LivePageTemplateOne';
import LivePageTemplateTwo from './LivePageTemplateTwo';
import LivePageTemplateThree from './LivePageTemplateThree';
import axiosInstance from '../../config/axios';


interface IProps {
    userData: MembersTypeRes.IMember | null,
    groupData: MembersTypeRes.IGroupAssociation | null,
    chatRoomID: string,
    isPlaylistEnabled: boolean,
    mainTemplate: TMainTemplate
}

const LivePageUserContainer = ({ userData, groupData, chatRoomID, isPlaylistEnabled, mainTemplate }: IProps) => {

    // Checking if user is from china
    // let isChina = false;
    // if(userData !== null && userData.anagraphics.length > 0 && userData.anagraphics[0].country.countryID === 48){
    //     isChina = true;
    // }

    useEffect(() => {
        // Register logs
        // axiosInstance.get('Contents/emea-town-hall-10-may')
    }, [])

    const components = {
        BeforeEvent: <LivePageBeforeEvent />,
        TemplateOne: <LivePageTemplateOne userData={userData} groupData={groupData} />,
        TemplateTwo: <LivePageTemplateTwo userData={userData} groupData={groupData} />,
        TemplateThree: <LivePageTemplateThree userData={userData} groupData={groupData} />,
    };
    const Component = components[mainTemplate];

    return Component
}

export default LivePageUserContainer;